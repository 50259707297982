import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-post-button',
  templateUrl: './new-post-button.component.html',
  styleUrls: ['./new-post-button.component.scss']
})
export class NewPostButtonComponent {

  constructor(private router: Router) { }


  redirectToHome(): void {
    window.scrollTo({top: 0});
    window.location.reload();
  }
  
}
