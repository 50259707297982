import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {InboxComponent} from "../pages/inbox/inbox.component";
import {environment} from "../environments/environment";
import {ChatDTO} from "../models/chat/chatDTO";
import {ChatForm} from "../models/chat/chatForm";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {Conversation} from "../models/chat/conversation";
import {Notification} from "../models/notification";
import {UserLogged} from "./user-logged";
import {User} from "../models/user/user";
import {NewConversation} from "../models/chat/newConversation";

@Injectable({
  providedIn: 'root'
})
export class WebSocketAPI {
  private user:User;
  public chat:Subject<ChatDTO> = new Subject<ChatDTO>();
  public notification:Subject<Notification[]> = new Subject<Notification[]>();
  public post:Subject<any> = new Subject<any>();
  public conversation:Subject<Conversation> = new Subject<Conversation>();
  public conversations:Subject<Conversation[]> = new Subject<Conversation[]>();
  public unreadMessages:Subject<number> = new Subject<number>();
  public ws:any;
  public jwt:string = "";
  webSocketEndPoint: string = environment.apiEndpoint + 'ws';
  topicMessage: string = "/topic/message";
  topicNotification: string = "/topic/notification";
  topicConversation: string = "/topic/conversation";
  topicNewConversations: string = "/topic/newmessageconversation";
  topicPost: string = "/topic/updatePost";
  stompClient: any;
  headers = {
    origin: environment.appURL,
    authorization: 'Bearer ' + localStorage.getItem('jwt')
  }

  constructor(
    private userLogged:UserLogged
  ) {
    this.unreadMessages.next(0);
    this.user = userLogged.user;
    this.jwt = <string> localStorage.getItem("jwt");
    this.ws = new SockJS(this.webSocketEndPoint,null,{sessionId: this.user.id});
    this.stompClient = Stomp.over(this.ws);
    this.stompClient.debug = function(str:string) {};
  }

  _connect() {
    const _this = this;
    if(this.stompClient.connected) {
      _this.stompClient.subscribe(_this.topicMessage+'-'+this.user.id, function (sdkEvent:any) {
        _this.onMessageReceived(sdkEvent);
      });
    } else {
      this.connectWebSocket();
      setTimeout(() => {
        this._connect();
      }, 500);
    }
  };

  _connectConversations() {
    const _this = this;
    if(this.stompClient.connected) {
      _this.stompClient.subscribe(_this.topicConversation+'-'+this.user.id, function (sdkEvent:any) {
        _this.onNewConversationReceived(sdkEvent);
      });
    } else {
      this.connectWebSocket();
      setTimeout(() => {
        this._connectConversations();
      }, 500);
    }
  }

  _connectNewMessagesConversations() {
    let _this = this;
    if(this.stompClient.connected) {
      this.stompClient.subscribe(_this.topicNewConversations+'-'+this.user.id, function (sdkEvent:any) {
        _this.newmessageconversation(sdkEvent);
      });
    } else {
      this.connectWebSocket();
      setTimeout(() => {
        this._connectNewMessagesConversations();
      }, 500);
    }
  }

  _connectNotifications() {
    const _this = this;
    if(this.stompClient.connected) {
      setTimeout(() => {
        _this.stompClient.subscribe(_this.topicNotification+'-'+this.user.id, function (sdkEvent:any) {
          _this.onNotificationReceived(sdkEvent);
        });
      }, 1000);
    } else {
      this.connectWebSocket();
      setTimeout(() => {
        this._connectNotifications();
      }, 500);
    }
  };

  _connectPosts() {
    const _this = this;
    if(this.stompClient.connected) {
      _this.stompClient.subscribe(_this.topicPost+'-'+this.user.id, function (sdkEvent:any) {
        _this.onPostReceived(sdkEvent);
      });
    } else {
      this.connectWebSocket();
      setTimeout(() => {
        this._connectPosts();
      }, 500);
    }
  };

  public setNewChat(newChat:ChatDTO) {
    if(newChat.idRequest == this.user.id)
      this.chat.next(newChat);
  }

  public setNewConversation(newC:Conversation[]) {
    if(newC != null && newC.length > 0 && newC[0].idRequest == this.user.id)
      this.conversations.next(newC);
  }

  connectWebSocket() {
    this.stompClient.connect(this.headers);
  }

  _disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }

  /**
   * Send message to sever via web socket
   * @param {*} message
   */
  _send(message:ChatForm) {
    this.stompClient.send("/app/message", {}, JSON.stringify(message));
  }

  checkNewMessages(conversationId:number, sender:number, receiver:number) {
    this.stompClient.send("/app/checknewmessage", {}, JSON.stringify({conversationId, sender, receiver}));
  }

  checkNewMessagesConversation(userId:number) {
    this.stompClient.send("/app/messageconversation", {}, userId);
  }

  readNewMessage(chatId:number) {
    this.stompClient.send("/app/updatemessage", {}, chatId);
  }

  checkNewNotifications(userId:number) {
    this.stompClient.send("/app/notification", {}, userId);
  }

  checkNewPosts(idMaisRecente:number) {
    this.stompClient.send("/app/updatePost", {}, JSON.stringify({idMaisRecente: idMaisRecente, idUser: this.user.id}));
  }

  updateReadedNotifications(notificationId:number) {
    this.stompClient.send("/app/readed", {}, notificationId);
  }

  onNotificationReceived(notification:any) {
    // @ts-ignore
    let n:Notification[] = JSON.parse(notification.body);
    if(n != null && n.length > 0 && n[0].idUser == this.user.id)
      this.notification.next(n);
  }


  onPostReceived(notification:any) {
    // @ts-ignore
    let n:any = JSON.parse(notification.body);
    this.post.next(n.body);
  }

  onMessageReceived(message:any) {
    // @ts-ignore
    let chat:ChatDTO = JSON.parse(message.body);
    if(chat.idRequest == this.user.id)
      this.setNewChat(chat);
  }

  onNewConversationReceived(c:any) {
    // @ts-ignore
    let conversation:Conversation[] = JSON.parse(c.body);
    if(conversation != null && conversation.length > 0 && conversation[0].idRequest == this.user.id)
      this.setNewConversation(conversation);
  }

  newmessageconversation(c:any) {
    // @ts-ignore
    let newC:NewConversation = JSON.parse(c.body);
    if(newC.idUser == this.user.id) {
      this.unreadMessages.next(newC.countNewConversation);
    }
  }

  checkNewConversations(id: number, idAnuncio: number) {
    this.stompClient.send("/app/checknewconversation", {}, JSON.stringify({id: id, idAnuncio: idAnuncio}));
  }
}
