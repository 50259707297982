import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MyAdType } from 'src/app/models/anuncio/meuAnuncio';

@Component({
  selector: 'app-meus-anuncios-filter',
  templateUrl: './meus-anuncios-filter.component.html',
  styleUrls: ['./meus-anuncios-filter.component.scss'],
})
export class MeusAnunciosFilterComponent implements OnChanges {
  @Input() pageMode: MyAdType = 'freelancer';
  @Output() filterChanges = new EventEmitter<FormGroup>();

  myAdsFilter: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.myAdsFilter = this.formBuilder.group({
      status: null,
      title: '',
      providerName: '',
      messageStatus: 'all',
    });

    this.myAdsFilter.valueChanges.subscribe((value) => {
      this.filterChanges.emit(this.myAdsFilter);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pageMode'] && !changes['pageMode'].firstChange) {
      this.myAdsFilter.get('title')?.setValue('');
      this.myAdsFilter.get('providerName')?.setValue('');
      this.myAdsFilter.get('status')?.setValue(null);
    }
  }
}
