import { Component } from '@angular/core';
import {CompartilharComponent} from "../componentes/compartilhar/compartilhar.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-leilao-pagina',
  templateUrl: './leilao-pagina.component.html',
  styleUrls: ['./leilao-pagina.component.scss'],
})
export class LeilaoPaginaComponent {
  public ultimoLance: number = 760;
  public novoLance:number = this.ultimoLance;
  public lanceUltrapassado: number = 750;
  public seletorImg: number = 0;
  constructor(
    public routes: Router,
  ){}


  public imagensLeilao = [
    'https://img.freepik.com/fotos-gratis/edificio-de-colheita-em-construcao_23-2147785501.jpg?w=1060&t=st=1685745226~exp=1685745826~hmac=893518a67256c984805a3d155a4f3996aa5dfb7363517b80d5e533f552fe9436',
    'https://img.freepik.com/fotos-premium/trabalhador-do-coordenador-de-construcao-engenheiro-civil-que-verifica-o-trabalho-no-canteiro-de-obras-conceito-da-construcao-da-casa_61243-627.jpg?w=2000',
    'https://img.freepik.com/fotos-gratis/close-do-trabalhador-em-frente-as-janelas-e-olhando-para-dentro_181624-56641.jpg?w=1060&t=st=1685745320~exp=1685745920~hmac=fda3ab1b2d5df0ec9a4c5ba30361a83b8c3008bd0880aaf0e96c8ef5492d021b',
  ];
//   VALIDAR SOMENTE NÚMEROS ACIMA DO ULTIMO LANCE


  selectImg(img:number) {
    this.seletorImg = img;
  }

  addValor(){
    this.novoLance += 2;
  }

  removeValor(){
    this.novoLance > this.ultimoLance ? this.novoLance -= 2 : this.novoLance = this.novoLance;
  }

  setNovoLance(){
    this.ultimoLance = this.novoLance;
  }


}
