import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Leilao} from "../../../models/leilao/leilao";
import {LeilaoService} from "../../../services/leilao.service";

@Component({
  selector: 'app-feed-leiloes',
  templateUrl: './feed-leiloes.component.html',
  styleUrls: ['./feed-leiloes.component.scss']
})
export class FeedLeiloesComponent implements OnInit {

  public leiloes:Leilao[] = new Array<Leilao>();

  constructor(
    public routes: Router,
    private leilaoService:LeilaoService
  ) {

  }

  ngOnInit() {
    this.leilaoService.getAllActive().subscribe(l => this.leiloes = l);
  }
}
