import {Component, OnInit} from '@angular/core';
import {Payment} from "../../../models/admin/payment";
import {getNameTypePayment, UtilsModule} from "../../../utils";
import {AdminService} from "../../../services/admin.service";

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  public payments:Payment[] = new Array<Payment>();

  constructor( private adminService:AdminService,
  ) {
  }

  ngOnInit() {
    this.adminService.getPayments().subscribe(p => {
      this.payments = p;
    });
  }

  protected readonly UtilsModule = UtilsModule;
  protected readonly getNameTypePayment = getNameTypePayment;
}
