import { Component } from '@angular/core';
import {ControllerCarouselService} from "../../../services/controller-carousel.service";
import {Router, RouterModule} from "@angular/router";


@Component({
  selector: 'app-carrossel-leiloes',
  templateUrl: './carrossel-leiloes.component.html',
  styleUrls: ['./carrossel-leiloes.component.scss', '../../../../darkmode.scss']
})
export class CarrosselLeiloesComponent {


  constructor(
    private _controllerCarouselService: ControllerCarouselService,
    public routes: Router,
  ) {}
  getBgCard(){
    let bg: string = '/assets/_img/leilao/bg-leilao-card.png';
    /*if(linkBg = ''){
      bg = ;
    } else{
      bg = linkBg;
    }*/
    return bg;
  }
}

