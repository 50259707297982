import {Plano} from "./plano";

export class PlanoModel implements Plano {
  id!:number;
  nome!:string;
  valor!:number;
  valorOriginal!:number;
  valorTippz!:number;
  posicao!:string;
  descricao!:string;
  idProdutoStripe!:string;
}
