import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Leilao} from "../models/admin/leilao";

@Injectable({
  providedIn: 'root'
})
export class LeilaoService {

  private urlLeilao = ApiService.URL + "leilao/";

  constructor(
    private service: ApiService
  ) { }

  public getAllActive() {
    return this.service.get(this.urlLeilao + "active");
  }

  public getAll() {
    return this.service.get(this.urlLeilao);
  }
  public async save(leilao:Leilao) {
    return await this.service.post(this.urlLeilao, leilao).toPromise();
  }
  public getById(id:number) {
    return this.service.get(this.urlLeilao + id).toPromise();
  }
}
