import {UserLanguage} from "./userLanguage";
import {Language} from "./language";

export class UserLanguageModel implements UserLanguage {
  id!: number;
  userId!:number;
  language!: Language;
  languageId!: number;
  levelLang!:number;
}
