import { Component } from '@angular/core';

@Component({
  selector: 'app-leiloes',
  templateUrl: './leiloes.component.html',
  styleUrls: ['./leiloes.component.scss']
})
export class LeiloesComponent {

}
