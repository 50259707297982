export interface MeuAnuncio {
  id: number;
  title: string;
  publishedIn: Date;
  status: MyAdStatus;
  paymentType: string;
  type: string;
  privado: boolean;
  totalMessages:number;
  unreadMessages: number;
}

export type MyAdType = 'freelancer' | 'client';

export enum MyAdStatus {
  ANALYSING_PROPOSALS = 0,
  CLOSED_PROPOSALS = 1,
  PROJECT_CONCLUDED = 2,
  CANCELED = 3,
}

export interface MyAdsFilters {
  pageMode: MyAdType;
  status?: MyAdStatus;
  title: string;
  providerName: string,
  currentPage: number;
  totalPages: number;
}
