import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    public user: any;
    public lastPage: any;
    constructor(
        private router: Router
    ) {
      // @ts-ignore
      this.user = JSON.parse(localStorage.getItem('user')) || {};
    }

    canActivate(): boolean {
        if (!this.user.id) {
            this.router.navigate(['/']);
            return false;
        } else {
            return true;
        }
    }
}
