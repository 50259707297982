import { Component } from '@angular/core';
import {addTypographyClass} from "@angular/material/schematics/ng-add/theming/theming";
import {User} from "../../../models/user/user";
import { ActivatedRoute } from '@angular/router';
import {AnuncioFeedModel} from "../../../models/anuncio/anuncioFeedModel";

@Component({
  selector: 'app-novo-anuncio',
  templateUrl: './novo-anuncio.component.html',
  styleUrls: ['./novo-anuncio.component.scss','../anuncio.component.scss', '../../../../darkmode.scss']
})
export class NovoAnuncioComponent {
 anuncio:object | undefined

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const state = history.state;
    if (state && state.anuncio) {
       this.anuncio = state.anuncio;
    } else {
      this.anuncio = new AnuncioFeedModel();
    }
  }
}
