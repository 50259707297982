import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {environment} from "../environments/environment";

const URL = environment.apiEndpoint;

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  constructor(
    private apiService:ApiService
  ) { }

  public getDashboard() {
    return this.apiService.get(URL + 'admin/dashboard');
  }

  public getPayments() {
    return this.apiService.get(URL + "payment");
  }
}
