import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public timeout = 100000000 * 60;

  static URL = environment.apiEndpoint;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    timeout: this.timeout,
  };

  constructor(private http: HttpClient) {}

  get(url: string): Observable<any> {
    return this.getParams(url, null);
  }

  getParams(url: string, params: any): Observable<any> {
    this.setBearerToken();
    if (params) {
      const u = new URLSearchParams(params.params).toString();

      if (url.includes('?')) {
        params = `&${u}`;
      } else {
        params = `?${u}`;
      }
      url = url + params;
    }

    return this.http.get(url, this.httpOptions);
  }

  post(url: string, data: any): Observable<any> {
    this.setBearerToken();
    return this.http.post(url, data, this.httpOptions);
  }
  postT<T>(url: string, data: any): Observable<any> {
    this.setBearerToken();
    return this.http.post<T>(url, data, this.httpOptions);
  }

  postNoBearerToken(url: string, data: any): Observable<any> {
    return this.http.post(url, data, this.httpOptions);
  }

  postJs(url: string, data: any): Observable<any> {
    this.httpOptions.headers = this.httpOptions.headers.set(
      'Content-Type',
      'application/javascript',
    );
    this.setBearerToken();
    return this.http.post(url, data, this.httpOptions);
  }

  postUpload(url: string, data: any): Observable<any> {
    this.setBearerToken();
    return this.http.post<any>(url, data, {
      headers: new HttpHeaders(),
    });
  }

  postBlob(url: string, data: any) {
    this.setBearerToken();
    return this.http.post(url, data, {
      headers: new HttpHeaders(),
      responseType: 'blob',
    });
  }

  getBlob(url: string) {
    this.setBearerToken();
    return this.http.get(url, {
      headers: new HttpHeaders(),
      responseType: 'blob',
    });
  }

  signUp(url: string, data: any): Observable<any> {
    return this.http.post(url, data, this.httpOptions);
  }

  login(url: string, data: any): Observable<any> {
    return this.http.post(url, data, this.httpOptions);
  }

  put(url: string, data: any): Observable<any> {
    this.setBearerToken();
    return this.http.put(url, data, this.httpOptions);
  }

  delete(url: string): Observable<any> {
    this.setBearerToken();
    return this.http.delete(url, this.httpOptions);
  }

  setBearerToken() {
    const jwt = localStorage.getItem('jwt');
    if(jwt != null)
      this.httpOptions.headers = this.httpOptions.headers.set(
        'Authorization',
        'Bearer ' + jwt,
      );
  }
}
