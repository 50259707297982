import {Component} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {Categoria} from "../../../models/categoria/categoria";

@Component({
  selector: 'app-filter-leiloes',
  templateUrl: './filter-leiloes.component.html',
  styleUrls: ['./filter-leiloes.component.scss'],
})
export class FilterLeiloesComponent {

  public selectorData: any = "Data do leilão";
  public controllerData: number|undefined;

  public selectorCategoria: any = "Categoria";
  public controllerCategoria: Array<String> = [];

  public selectorSubcategoria: any = "Subcategoria";
  public controllerSubcategoria: Array<String> = [];

  public selectorLocalidade: any = "Localidade";
  public controllerLocalidade: Array<String> = [];


  public arrDatas = [
    {id:'qualquer-momento',text:'A qualquer momento'},
    {id:'ultima-semana',text:'Última semana'},
    {id:'ultimas-24h',text:'Últimas 24 horas'},
    {id:'ultimo-mes',text:'Último mês'},
  ]

  public arrCategorias = [
    {id:'categoria-0',text:'Informática'},
    {id:'categoria-1',text:'T.I'},
    {id:'categoria-2',text:'Autos'},
    {id:'categoria-3',text:'Casa'},
  ]

  public arrSubcategorias = [
    {id:'subcateogoria-0',text:'Conserto de Computador'},
    {id:'subcateogoria-1',text:'Celulares'},
    {id:'subcateogoria-2',text:'Pneus'},
    {id:'subcateogoria-3',text:'Jardinagem'},
  ]

  public arrLocalidades = [
    {id:'localidade-0',text:'Natal - RN'},
    {id:'localidade-1',text:'Pato Branco - PR'},
    {id:'localidade-2',text:'Goiania - GO'},
  ]

  resetFilter(filter:any){
    if (filter === "data") {
      this.selectorData = "Data do leilão";
      this.controllerData = undefined;
    }
    if (filter === "categoria") {
      this.controllerCategoria = [];
    }
    if (filter === "subcategoria") {
      this.controllerSubcategoria = [];
    }
    if (filter === "localidade") {
      this.controllerLocalidade = [];
    }
  }



  changeFilterData(filter:any, opcao:number) {
    this.selectorData = filter;
    this.controllerData = opcao;
  }

  changeFilterCategoria(opcao:string) {
    if(this.controllerCategoria.find(c => c==opcao)){
      this.controllerCategoria = this.controllerCategoria.filter(c => c!=opcao);
    } else{
      this.controllerCategoria.push(opcao);
    }
  }
  changeFilterSubcategoria(opcao:string) {
    if(this.controllerSubcategoria.find(c => c==opcao)){
      this.controllerSubcategoria = this.controllerSubcategoria.filter(c => c!=opcao);
    } else{
      this.controllerSubcategoria.push(opcao);
    }
  }
  changeFilterLocalidade(opcao:string){
    if(this.controllerLocalidade.find(c => c==opcao)){
      this.controllerLocalidade = this.controllerLocalidade.filter(c => c!=opcao);
    } else{
      this.controllerLocalidade.push(opcao);
    }
  }


  isSelectedCat(controllerCategoria: Array<String>, id: string) {
    return controllerCategoria.find(c => c == id);
  }
  isSelectedSubcat(controllerSubcategoria: Array<String>, id: string) {
    return controllerSubcategoria.find(c => c == id);
  }
  isSelectedLocal(controllerLocalidade: Array<String>, id: string) {
    return controllerLocalidade.find(c => c == id);
  }
}
