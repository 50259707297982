import { Component, Input } from '@angular/core';
import { MeuAnuncio, MyAdStatus } from '../../../models/anuncio/meuAnuncio';
import { UtilsModule } from 'src/app/utils';
import {Router} from "@angular/router";

@Component({
  selector: 'app-meu-anuncio-card',
  templateUrl: './meu-anuncio-card.component.html',
  styleUrls: ['./meu-anuncio-card.component.scss'],
})
export class MeuAnuncioCardComponent {
  protected readonly UtilsModule = UtilsModule;
  // @ts-ignore
  @Input() myAd: MeuAnuncio;
  @Input() pageMode:string='freelancer';
  statusStyle: string = '';
  showMenu = false;

  constructor(
    private routes:Router
  ) {
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  buildAdStatus(status: MyAdStatus): string {
    switch (status) {
      case MyAdStatus.ANALYSING_PROPOSALS:
        return 'Analisando Propostas';
      case MyAdStatus.CLOSED_PROPOSALS:
        return 'Propostas Fechadas';
      case MyAdStatus.PROJECT_CONCLUDED:
        return 'Concluído';
      case MyAdStatus.CANCELED:
        return 'Cancelado'
      default:
        return '';
    }
  }

  getStatusStyle(status: MyAdStatus): string {
    switch (status) {
      case MyAdStatus.ANALYSING_PROPOSALS:
        return 'analyzing';
      case MyAdStatus.CLOSED_PROPOSALS:
        return 'canceled';
        case MyAdStatus.CANCELED:
          return 'canceled';
      case MyAdStatus.PROJECT_CONCLUDED:
        return 'performed';
      default:
        return '';
    }
  }

  openAnuncio() {
    let titulo = UtilsModule.accentsTidy(this.myAd.title.replaceAll(" ", "-")).toLowerCase();
    localStorage.setItem("viewAnuncio", JSON.stringify(this.myAd));
    this.routes.navigate(['job/' + this.myAd.id + '-' + titulo]);
  }
}
