import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {LoginService} from "../../../services/login.service";
import {Router} from "@angular/router";
import {NgToastService} from "ng-angular-popup";
import {LoaderService} from "../../../services/loader.service";
import {SocialAuthService} from "angularx-social-login";
import {environment} from "../../../environments/environment";
import {PaymentService} from "../../../services/payment.service";
import {User} from "../../../models/user/user";
import {UserLogged} from "../../../services/user-logged";
import {UtilsModule} from "../../../utils";
import {Plano} from "../../../models/plano";
import {Payment} from "../../../models/user/payment";
import {PaymentModel} from "../../../models/user/paymentModel";

@Component({
  selector: 'app-confirm-cancel-plan',
  templateUrl: './confirm-cancel-plan.component.html',
  styleUrls: ['./confirm-cancel-plan.component.scss']
})
export class ConfirmCancelPlanComponent implements OnInit {

  protected readonly UtilsModule = UtilsModule;

  public user:User;
  public currentPlan:Plano;
  public currentPayment:Payment= new PaymentModel();
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private routes:Router,
    private toast:NgToastService,
    private paymentService:PaymentService,
    private userLogged:UserLogged,
  ) {
    this.user = userLogged.user;
    this.currentPlan = this.user.plano;
  }

  ngOnInit() {
    this.currentPayment = this.user.payments.filter(p => p.plano != null && this.currentPlan.id == p.plano.id)[0];
  }

  cancelPlan() {
    this.paymentService.cancelPlan(this.user).subscribe(status => {
      this.user = this.userLogged.user;
      this.user.statusPlano = status;
      localStorage.setItem("user", JSON.stringify(this.user));
      this.toast.info({
        detail: 'INFO',
        summary: 'Seu plano foi cancelado e você terá acesso até ' + UtilsModule.DateToStringBR(UtilsModule.getDataCancelPlan(this.currentPayment.dttPayment)) + '. Espero que você volte.',
        duration: 5000,
        position: 'tr'
      });
      this.bsModalRef.hide();
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    });
  }
}
