import {Component} from '@angular/core';
import {UserLogged} from "../../../services/user-logged";
import {User} from "../../../models/user/user";
import {LoginService} from "../../../services/login.service";
import {WebSocketAPI} from "../../../services/WebSocketAPI";
import {Notification} from "../../../models/notification";

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss', '../../../../darkmode.scss']
})


export class NotificacoesComponent {
  public isLogged: boolean = false;
  public user: User;
  public readed: Notification[] = [];
  public flagNotification:number = 0;

  constructor(
    private userLogged: UserLogged,
    private loginService: LoginService,
    private webSocketApi:WebSocketAPI
  ) {
    this.user = userLogged.user;

    this.readed = this.user.notifications.filter(n => n.readed == 0);

    if(this.user.notifications == null)
      this.user.notifications = new Array<Notification>();
    this.isLogged = userLogged.isLogged;
  }

  readedNotification(notificationId:number){
    this.flagNotification = 1;
    this.setSession(notificationId);
    this.readed = this.user.notifications.filter(n => n.readed == this.flagNotification);

  }

  unreadedNotification(notificationId:number){
    this.flagNotification = 0;
    this.setSession(notificationId);
    this.readed = this.user.notifications.filter(n => n.readed == this.flagNotification);
  }

  async setSession(notificationId:number) {
    this.webSocketApi.updateReadedNotifications(notificationId);
    this.user = await this.loginService.getUser(this.user.id);
    this.readed = this.user.notifications.filter(n => n.readed == this.flagNotification);
    localStorage.setItem("user", JSON.stringify(this.user));
  }
}
