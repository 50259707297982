import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CadastroComponent} from "../cadastro/cadastro.component";
import {Router} from "@angular/router";
import {SenhaComponent} from "../senha/senha.component";
import {User} from "../../../models/user/user";
import {environment} from "../../../environments/environment";
import {LoginService} from "../../../services/login.service";
import {NgToastService} from "ng-angular-popup";
import jwt_decode from 'jwt-decode';
import {LoaderService} from "../../../services/loader.service";

declare const FB: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../../../../darkmode.scss'],
})
export class LoginComponent implements OnInit {

  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private loginService:LoginService,
    private routes:Router,
    private toast:NgToastService,
    private loaderService:LoaderService
  ) {}

  ngOnInit() {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: this.handleCredentialResponse.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,

    });
    // @ts-ignore
    google.accounts.id.renderButton(
      // @ts-ignore
      document.getElementById("google-button"),
       { theme: "outline", size: "large", width: "250px", shape:"pill" }
    );
    // @ts-ignore
    google.accounts.id.prompt((notification: PromptMomentNotification) => {});

    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '1047122539993061',
        cookie: true,
        xfbml: true,
        version: 'v17.0',
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      // @ts-ignore
      js.src = 'https://connect.facebook.net/pt_BR/sdk.js';
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    this.testAPI();
  }

  async handleCredentialResponse(response: any) {
    let user = jwt_decode(response.credential);
    let logged = await this.loginService.loginGoogle(user);
    if(logged) {
      this.bsModalRef.hide();
      document.location.href = '/';
    } else {
      this.toast.warning({
        summary: 'Ocorreu um erro ao realizar o login, tente novamente mais tarde.',
        duration: 5000,
        position: 'tr',
      });
    }
  }

  async login(form:any) {
    this.loaderService.show();
    if(form.username != undefined && form.username != "" && form.password != undefined && form.password != "") {
      let logged = await this.loginService.login(form.username, form.password);
      this.loaderService.hide();
      if(logged) {
        this.bsModalRef.hide();
        document.location.href = '/';
      } else {
        this.toast.warning({
          summary: 'Usuário não encontrado ou senha incorreta',
          duration: 5000,
          position: 'tr'
        });
      }
    }
  }

  public testAPI() {
    console.log('Welcome!  Fetching your information.... ');
    FB.api('/me', function(response:any) {
      console.log('Successful login for: ' + response.name);
    });
  }
  public openModalCadastro() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(CadastroComponent,{backdrop:true});
  }

  loginWithFacebook(): void {
    FB.login(function(response:any) {
      console.log(response);
    }, {scope: 'public_profile,email'});
  }

  public openModalRecuperarSenha() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(SenhaComponent,{backdrop:true});
  }

}
