import {User} from "../user/user";
import {Proposta} from "./proposta";
import {Anuncio} from "./anuncio";
import {AnuncioFeed} from "./anuncioFeed";
import {ConversationAnexo} from "../chat/conversationAnexo";

export class PropostaModel implements Proposta {
  id!: number;
  previsao!: string;
  descricao!: string;
  dttProposta!: Date;
  disponibilidade!: string;
  habilidades!: string;
  infos!: string;
  pitch!: string;
  experiencia!: string;
  status!: number;
  tipoValor!: number;
  valor!: number;
  prestadorId!: number;
  prestador!:User;
  anuncio!:AnuncioFeed;
  anuncioId!:number;
  anexos!:ConversationAnexo[];
}
