import {Component, Input} from '@angular/core';
import {timeCounter} from "../../../models/timeCounter";

import { interval, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import {end} from "@popperjs/core";

function calcDateDiff(endDay:Date, closure:number): timeCounter{
  endDay = new Date(endDay);
  const finalLeilaoDia = endDay;
  finalLeilaoDia.setHours(finalLeilaoDia.getHours() + closure);

  const milliSecondsInASecond = 1000;
  const hoursInADay = 24;
  const minutesInAnHour = 60;
  const secondsInAMinute = 60;

  const timeDifference = finalLeilaoDia.valueOf() - Date.now();

  const daysToDday = Math.floor(
    timeDifference /
    (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
  );

  const hoursToDday = Math.floor(
    (timeDifference /
      (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
    hoursInADay
  );

  const minutesToDday = Math.floor(
    (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
    secondsInAMinute
  );

  const secondsToDday =
    Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;



  return { secondsToDday, minutesToDday, hoursToDday, daysToDday };
}

@Component({
  selector: 'app-time-counter',
  templateUrl: './time-counter.component.html',
  styleUrls: ['./time-counter.component.scss']
})
export class TimeCounterComponent{
  @Input()
  public inicio:Date = new Date();
  @Input()
  public closure:number=0;

  public timeLeft$: Observable<timeCounter>;

  constructor() {
    this.timeLeft$ = interval(1000).pipe(
      map(x => calcDateDiff(this.inicio, this.closure)),
      shareReplay(1)
    );
  }


}
