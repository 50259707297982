import { Component } from '@angular/core';

@Component({
  selector: 'app-moedas',
  templateUrl: './moedas.component.html',
  styleUrls: ['./moedas.component.scss', '../../../../darkmode.scss']
})
export class MoedasComponent {
  public editChangeG1: boolean = false;
  public editChangeG2: boolean = false;

  constructor() {
  }

  public editChangeEG(group:string): void{
    if(group == 'G1') {
      this.editChangeG1 = !this.editChangeG1;
    }
    if(group == 'G2') {
      this.editChangeG2 = !this.editChangeG2;
    }

  }
}
