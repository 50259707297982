import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder } from '@angular/forms';

function padLeftWithZeroes(value: number, digits: number) {
  // @ts-ignore
  return String(value).padStart(digits,'0');
}

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})

export class UtilsModule {

  public static formatCurrency(value:number) : string {
    if(value != null && value != undefined)
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    else
      return "";
  }
  public static StringBRToDate(date: string): Date | null {
    try {
      return new Date(Number(date.split('/')[2]), Number(date.split('/')[1]), Number(date.split('/')[0]));
    } catch (e) {
      return null;
    }
  }

  public static DateToStringBR(date: Date): string | null {
    try {
      date = new Date(date);
      return padLeftWithZeroes(date.getDate(),2) + '/' + padLeftWithZeroes((date.getMonth() + 1),2) + '/' + date.getFullYear();
    } catch (e) {
      return null;
    }
  }

  public static DateTimeToDate(datetime: Date) {
    return new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());
  }

  public static NumberTimeToString(datetime: number, incluirSegundos?: boolean): String {
    const data = new Date(datetime);
    return UtilsModule.TimeToString(data, incluirSegundos);
  }

  public static TimeToString(datetime: Date, incluirSegundos?: boolean): String {
    const data = new Date(datetime);
    return data.getHours() + ':' + (data.getMinutes() < 10 ? '0' : '') + data.getMinutes() + (incluirSegundos ? ':' + data.getSeconds() : '');
  }

  public static padLeftWithZeroes(value:number,digits:number) {
    return padLeftWithZeroes(value,digits);
  }

  public static DateToFullStringBR(date: Date): String {
    date = new Date(date);
    const day = padLeftWithZeroes(date.getDate(),2);
    const month = padLeftWithZeroes((date.getMonth() + 1),2);
    const hour = padLeftWithZeroes(date.getHours(),2);
    const minute = padLeftWithZeroes(date.getMinutes(),2);
    const second = padLeftWithZeroes(date.getSeconds(),2);
    return day + '/' + month + '/' + date.getFullYear() + ' ' + hour + ':' + minute + ':' + second;
  }

  public DateToString(datetime: Date): String {

    let descricaoMes: String = '';
    switch (datetime.getMonth()) {
      case 0:
        descricaoMes = 'Janeiro';
        break;
      case 1:
        descricaoMes = 'Fevereiro';
        break;
      case 2:
        descricaoMes = 'Março';
        break;
      case 3:
        descricaoMes = 'Abril';
        break;
      case 4:
        descricaoMes = 'Maio';
        break;
      case 5:
        descricaoMes = 'Junho';
        break;
      case 6:
        descricaoMes = 'Julho';
        break;
      case 7:
        descricaoMes = 'Agosto';
        break;
      case 8:
        descricaoMes = 'Setembro';
        break;
      case 9:
        descricaoMes = 'Outubro';
        break;
      case 10:
        descricaoMes = 'Novembro';
        break;
      case 11:
        descricaoMes = 'Dezembro';
        break;
    }

    return datetime.getDate() + ' de ' + descricaoMes + ' de ' + datetime.getFullYear();

  }

  public static calcularDiferencaTempoFeed(data: Date): string {
    const agora = new Date();
    data = new Date(data);
    const diferencaMilissegundos = agora.getTime() - data.getTime();

    // Calculando a diferença em minutos, horas, dias, semanas e meses
    const minutos = Math.floor(diferencaMilissegundos / (1000 * 60));
    const horas = Math.floor(diferencaMilissegundos / (1000 * 60 * 60));
    const dias = Math.floor(diferencaMilissegundos / (1000 * 60 * 60 * 24));
    const semanas = Math.floor(diferencaMilissegundos / (1000 * 60 * 60 * 24 * 7));
    const meses = Math.floor(diferencaMilissegundos / (1000 * 60 * 60 * 24 * 30));

    // Construindo a string de resultado
    let resultado = '1 - ';
    if(minutos == 1){
      resultado += `${minutos} minuto`;
    }else if (minutos < 60) {
      resultado += `${minutos} minutos`;
    }else if (horas == 1) {
      resultado += `${horas} hora`;
    }else if (horas < 24) {
      resultado += `${horas} horas`;
    }else if (dias == 1) {
      resultado += `${dias} dia`;
    }else if (dias < 30) {
      resultado += `${dias} dias`;
    }else if (semanas == 4) {
      resultado += `${semanas} semana`;
    } else if (semanas < 4) {
      resultado += `${semanas} semanas`;
    } else if (meses == 1) {
      resultado += `${meses} mes`;
    }else{
      resultado += `${meses} meses`;
    }

    return resultado;
  }

  public static calcularDiferencaTempo(data: Date): string {
    const agora = new Date();
    data = new Date(data);
    const diferencaMilissegundos = agora.getTime() - data.getTime();

    // Calculando a diferença em minutos, horas, dias, semanas e meses
    const minutos = Math.floor(diferencaMilissegundos / (1000 * 60));
    const horas = Math.floor(diferencaMilissegundos / (1000 * 60 * 60));
    const dias = Math.floor(diferencaMilissegundos / (1000 * 60 * 60 * 24));
    const semanas = Math.floor(diferencaMilissegundos / (1000 * 60 * 60 * 24 * 7));
    const meses = Math.floor(diferencaMilissegundos / (1000 * 60 * 60 * 24 * 30));

    // Construindo a string de resultado
    let resultado = 'Há ';
    if(minutos == 1){
      resultado += `${minutos} minuto`;
    }else if (minutos < 60) {
      resultado += `${minutos} minutos`;
    }else if (horas == 1) {
      resultado += `${horas} hora`;
    }else if (horas < 24) {
      resultado += `${horas} horas`;
    }else if (dias == 1) {
      resultado += `${dias} dia`;
    }else if (dias < 30) {
      resultado += `${dias} dias`;
    }else if (semanas == 4) {
      resultado += `${semanas} semana`;
    } else if (semanas < 4) {
      resultado += `${semanas} semanas`;
    } else if (meses == 1) {
      resultado += `${meses} mes`;
    }else{
      resultado += `${meses} meses`;
    }

    return resultado;
  }

  public static calcularMinutos(data: Date): number {
    const agora = new Date();
    data = new Date(data);
    const diferencaMilissegundos = agora.getTime() - data.getTime();

    // Calculando a diferença em minutos, horas, dias, semanas e meses
    let minutos = Math.floor(diferencaMilissegundos / (1000 * 60))

    return minutos;
  }

  public static formatarDataExtenso(data: Date): string {
    data = new Date(data);
    let options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    // @ts-ignore
    let formatter = new Intl.DateTimeFormat('pt-BR', options);
    let dataFormatada = formatter.format(data);

    return dataFormatada;
  }

  public static accentsTidy(s:string){
    var r=s.toLowerCase();
    r = r.replace(new RegExp(/[àáâãäå]/g),"a");
    r = r.replace(new RegExp(/æ/g),"ae");
    r = r.replace(new RegExp(/ç/g),"c");
    r = r.replace(new RegExp(/[èéêë]/g),"e");
    r = r.replace(new RegExp(/[ìíîï]/g),"i");
    r = r.replace(new RegExp(/ñ/g),"n");
    r = r.replace(new RegExp(/[òóôõö]/g),"o");
    r = r.replace(new RegExp(/œ/g),"oe");
    r = r.replace(new RegExp(/[ùúûü]/g),"u");
    r = r.replace(new RegExp(/[ýÿ]/g),"y");
    r = r.replace(new RegExp(/[^A-Z\s\d-]/ig),"");
    return r;
  }

  public static removeHtmlTags(s:string) {
    return s.replace(/<\/?[^>]+(>|$)/g, "");
  }

  public static getMonth(month: number) {
    switch (month) {
      case 1: return "Janeiro";
      case 2: return "Fevereiro";
      case 3: return "Março";
      case 4: return "Abril";
      case 5: return "Maio";
      case 6: return "Junho";
      case 7: return "Julho";
      case 8: return "Agosto";
      case 9: return "Setembro";
      case 10: return "Outubro";
      case 11: return "Novembro";
      case 12: return "Dezembro";
      default: return "";
    }
  }

  public static getLevelLang(levelLang: number) {
    switch (levelLang) {
      case 1: return "Básico A1 (Iniciante)";
      case 2: return "Básico A2 (Intermediário)";
      case 3: return "Intermediário B1 (Nível mínimo)";
      case 4: return "Intermediário B2 (Nível avançado)";
      case 5: return "Avançado C1 (Autônomo)";
      case 6: return "Avançado C2 (Fluente)";
      default: return "Indefinido";
    }
  }

  public static adicionarData(data:Date, quantidade:number, unidade:string) {
    if (unidade === 'dias') {
      data.setDate(data.getDate() + quantidade);
    } else if (unidade === 'meses') {
      data.setMonth(data.getMonth() + quantidade);
    } else if (unidade === 'anos') {
      data.setFullYear(data.getFullYear() + quantidade);
    }
    return data;
  }

  public static getDataCancelPlan(data:Date) {
    let dtCancel = new Date();
    data = new Date(data);
    const day = data.getDate();
    const month = ((new Date()).getMonth() + 1);
    const year = (new Date()).getFullYear();
    let newDate = new Date(year + "-" + padLeftWithZeroes(month,2) + "-" + padLeftWithZeroes(day, 2));
    dtCancel = UtilsModule.adicionarData(newDate, 30, 'dias');
    return dtCancel;
  }
}


export function getNameTypePayment(id:number) {
  switch (id) {
    case 1: return "Moedas";
    case 2: return "Plano";
    case 3: return "Proposta";
    case 4: return "Cancelamento de Compra";
    default: return "";
  }
}
