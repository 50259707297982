import {Component, HostListener} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {AppService} from "../../../app.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-downloadapp',
  templateUrl: './downloadapp.component.html',
  styleUrls: ['./downloadapp.component.scss', '../../../../darkmode.scss']
})
export class DownloadappComponent {

  public promptEvent: any;

  constructor(
    public bsModalRef:BsModalRef,
    public pwa: AppService
  ) {

  }

  installPWA(){
    this.pwa.installPWA();
  }
  installApp(){
    window.open('https://play.google.com/store/apps/details?id=br.com.tippz.twa', "blank_")
  }

  protected readonly Router = Router;
}
