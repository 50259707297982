import { Component } from '@angular/core';
import {UserLogged} from "../../../services/user-logged";
import {LoginComponent} from "../../modais/login/login.component";
import {CadastroComponent} from "../../modais/cadastro/cadastro.component";
import {DownloadappComponent} from "../../modais/downloadapp/downloadapp.component";
import {User} from "../../../models/user/user";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";

@Component({
  selector: 'app-controle-menu',
  templateUrl: './controle-menu.component.html',
  styleUrls: ['./controle-menu.component.scss', '../../../../darkmode.scss']
})
export class ControleMenuComponent {

  public user:User;
  public isLogged:boolean = false;
  public bsModalRef: BsModalRef | undefined;

  constructor(
    private modalService: BsModalService,
    public routes:Router,
    private userLogged:UserLogged
  ) {
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;
  }

  public openModalLogin() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true});
  }

  public openModalDownloadApp() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(DownloadappComponent, {backdrop: true});
  }

  public openModalCadastro() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(CadastroComponent, {backdrop: true});
  }


  protected readonly AuthService = UserLogged;
  protected readonly UserLogged = UserLogged;
}
