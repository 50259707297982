import { Component } from '@angular/core';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss', '../../../../darkmode.scss']
})
export class CheckoutComponent {

  constructor(
  ) {
  }

}
