import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {AnuncioService} from "../../../services/anuncio.service";
import {BehaviorSubject, interval} from 'rxjs';
import {Router} from "@angular/router";
import {UtilsModule} from "../../../utils";
import {LoginComponent} from "../../modais/login/login.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UserLogged} from "../../../services/user-logged";
import {User} from "../../../models/user/user";
import { NgToastService } from 'ng-angular-popup';
import {Meta} from "@angular/platform-browser";
import {AnuncioFeed} from "../../../models/anuncio/anuncioFeed";
import {LoginService} from "../../../services/login.service";
import {FilterLeiloesComponent} from "../filter-leiloes/filter-leiloes.component";
import { WebSocketAPI } from 'src/app/services/WebSocketAPI';
import { PostService } from 'src/app/services/shared-post/post.service';
import { Clipboard } from '@angular/cdk/clipboard';
import {environment} from "../../../environments/environment";
import {ModalCancelaAnuncioComponent} from "../modal-cancela-anuncio/modal-cancela-anuncio.component";
import {PromoverAnuncioComponent} from "../../modais/promover-anuncio/promover-anuncio.component";
@Component({
  selector: 'app-feed-posts',
  templateUrl: './feed-posts.component.html',
  styleUrls: ['./feed-posts.component.scss', '../../../../darkmode.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedPostsComponent {
  public anuncios:AnuncioFeed[] = new Array<AnuncioFeed>();
  public pageAnuncios = 0;
  public getNextPage = true;
  public filterCategoria;
  public filterTipoCategoria;
  public filterCidade;
  public kmCidade;
  public filterSearch;
  public bsModalRef: BsModalRef | undefined;
  protected readonly UtilsModule = UtilsModule;
  public isLogged:boolean = false;
  public user:User;
  public showNewPostButton:boolean = false;

  constructor(
    private routes:Router,
    private anuncioService:AnuncioService,
    private changeDetection: ChangeDetectorRef,
    private modalService: BsModalService,
    private toast:NgToastService,
    private userLogged:UserLogged,
    private loginService:LoginService,
    private meta:Meta,
    private webSocketApi:WebSocketAPI,
    private sharedService: PostService,
    private clipboard: Clipboard
  ) {
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;
    this.filterCategoria = localStorage.getItem('categoriaFilter');
    this.filterTipoCategoria = localStorage.getItem('tipoCategoriaFilter');
    this.filterSearch = localStorage.getItem('searchFilter');
    this.filterCidade = localStorage.getItem('cidadeFilter');
    this.kmCidade = localStorage.getItem('kmFilter');
  }



  ngOnInit(): void {
    this.loadAnuncios(this.pageAnuncios);
    this.scrollToTop();
    this.webSocketApi._connectPosts();
    setTimeout(() => {
      interval(5000).subscribe((val: number) => {
        const anuncioMaisRecente = this.anuncios.reduce((anuncioAtual: AnuncioFeed | null, anuncio: AnuncioFeed) => {
          if (!anuncioAtual) {
            return anuncio;
          }
          return anuncio.dttPublicacao > anuncioAtual.dttPublicacao ? anuncio : anuncioAtual;
        }, null);

        if (anuncioMaisRecente) {
          const idMaisRecente = anuncioMaisRecente.id;
          this.webSocketApi.checkNewPosts(idMaisRecente);
        }
      });
    },1000);

    this.webSocketApi.post.subscribe(n => {
      // @ts-ignore
      this.showNewPostButton = !!n?.body

      this.sharedService.updateShowNewPostButton(this.showNewPostButton);
    });

  }

  @HostListener('window:scroll', ['$event']) onScroll(e: Event): void {
    if((window.innerHeight + window.scrollY >= document.body.offsetHeight) && this.getNextPage) {
      this.pageAnuncios += 1 ;
      this.loadAnuncios(this.pageAnuncios);
    }
  }

  async showProfile(userId: number) {
    let user = await this.loginService.getUser(userId);
    localStorage.setItem("userProfile", JSON.stringify(user));
    let name = UtilsModule.accentsTidy(user.name.replaceAll(" ", "-")).toLowerCase();
    let profileURL = this.routes.createUrlTree(['client/' + name]).toString();
    this.routes.navigate([profileURL]);
  }

  async loadAnuncios(page:number) {
    let typeFilter = localStorage.getItem("typeFilter");
    let nextPage = [];
    switch(typeFilter) {
      case 'search':
        nextPage = await this.anuncioService.getList(page, AnuncioService.itensPerPage, typeFilter, this.filterSearch).toPromise();
        break;
      case 'categoria':
        nextPage = await this.anuncioService.getList(page, AnuncioService.itensPerPage, typeFilter, this.filterCategoria).toPromise();
        break;
      case 'tipoCategoria':
        nextPage = await this.anuncioService.getList(page, AnuncioService.itensPerPage, typeFilter, this.filterTipoCategoria).toPromise();
        break;
      case 'imagem':
        nextPage = await this.anuncioService.getList(page, AnuncioService.itensPerPage, typeFilter, 'imagem').toPromise();
        break;
      case 'cidade':
        let km = (this.kmCidade == null ? '35' : this.kmCidade);
        nextPage = await this.anuncioService.getList(page, AnuncioService.itensPerPage, typeFilter, this.filterCidade+":"+km).toPromise();
        break;
      case 'habilidade':
        nextPage = await this.anuncioService.getList(page, AnuncioService.itensPerPage, typeFilter, this.user.id).toPromise();
        break;
      default:
        nextPage = await this.anuncioService.getList(page, AnuncioService.itensPerPage, '', null).toPromise();
    }
    if(nextPage.length < AnuncioService.itensPerPage)
      this.getNextPage = false;
    if(this.anuncios.length == 0)
      this.anuncios = nextPage;
    else
      this.anuncios = [...this.anuncios, ...nextPage];
    this.changeDetection.detectChanges();
  }

  scrollToTop() {
    window.scrollTo({top: 0});
  }

  openAnuncio(anuncio:AnuncioFeed) {
    let titulo = UtilsModule.accentsTidy(anuncio.titulo.replaceAll(" ", "-")).toLowerCase();
    localStorage.setItem("viewAnuncio", JSON.stringify(anuncio));
    this.routes.navigate(['job/' + anuncio.id + '-' + titulo]);
  }

  public openModalLogin() {
    this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true});
  }

  public openNewProposta(anuncio:AnuncioFeed) {
    if(this.user.currency.value == 0) {
      this.toast.info({
        detail: 'INFO',
        summary: 'Necessário adquirir Tippz para realizar propostas.',
        duration: 5000,
        position: 'tr'
      });
    } else if(this.user.currency.value < anuncio.categoriaPrincipal.lance) {
      this.toast.info({
        detail: 'INFO',
        summary: 'Necessário adquirir mais Tippz para realizar proposta para esse anúncio.',
        duration: 5000,
        position: 'tr'
      });
    } else {
      localStorage.setItem("viewAnuncio", JSON.stringify(anuncio));
      this.routes.navigate(['proposta/new']);
    }
  }

  public openPropostas(anuncio:AnuncioFeed) {
    localStorage.setItem("viewAnuncio", JSON.stringify(anuncio));
    this.routes.navigate(['anuncio/meus-anuncios']);
  }

  inativePublication(anuncio: AnuncioFeed) {
    this.bsModalRef = this.modalService.show(ModalCancelaAnuncioComponent, {
      backdrop: true,
    });
    this.bsModalRef.content.content = anuncio;
  }

  promoverAnuncio(anuncio: AnuncioFeed) {
    if(this.user.currency.value >= 4) {
      this.bsModalRef = this.modalService.show(PromoverAnuncioComponent, {
        backdrop: true,
      });
      this.bsModalRef.content.content = anuncio;
    } else {
      this.toast.info({
        detail: 'INFO',
        summary: 'Para promover anúncio é necessário ter pelo menos 4 Tippz.',
        duration: 5000,
        position: 'tr'
      });
    }
  }

  edit(anuncio:AnuncioFeed): void {
    const data = {
      idAnuncio: anuncio.id,
    };
    this.routes.navigate(['/anuncio/editar'], { state: data });
  }

  copyAnuncioUrl(anuncio: AnuncioFeed): void {
    let titulo = UtilsModule.accentsTidy(anuncio.titulo.replaceAll(" ", "-")).toLowerCase();
    let url = environment.appURL + this.routes.createUrlTree(['job/' + anuncio.id + '-' + titulo]).toString().substring(1);
    this.clipboard.copy(url);
    this.toast.info({
      detail: 'INFO',
      summary: 'Link do anúncio ' + anuncio.titulo + ' copiado.',
      duration: 5000,
      position: 'tr'
    });
  }

  isPromotedToday(anuncio:AnuncioFeed):boolean {
    let dayOfWeek = (new Date()).getDay();
    if(anuncio.promoted && (anuncio.day1Promoted == dayOfWeek || anuncio.day2Promoted == dayOfWeek))
      return true;
    return false;
  }

  smallDescription(anuncio: AnuncioFeed) {
    return anuncio.descricao.length >= 200 ?
      anuncio.descricao.substring(0,200) + `...`
      : anuncio.descricao;
  }
}
