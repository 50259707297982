import {Component, Input, OnInit} from '@angular/core';
import {Plano} from "../../../models/plano";
import {Subject} from "rxjs";
import {ApiService} from "../../../services/api.service";
import {PaymentService} from "../../../services/payment.service";
import {PlanoModel} from "../../../models/planoModel";
import {User} from "../../../models/user/user";
import {UserLogged} from "../../../services/user-logged";
import {Router} from "@angular/router";
import {UtilsModule} from "../../../utils";

@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.scss', '../../../../darkmode.scss']
})
export class PlanosComponent {

  public user:User;
  public planos: Plano[] = new Array(new PlanoModel());
  public formatNamePlano = [''];

  constructor(
    private apiService:ApiService,
    private coinBuyService:PaymentService,
    private userLogged:UserLogged,
    public routes: Router
  ) {
    this.loadPlanos();
    this.user = userLogged.user;
  }

  async loadPlanos() {
    this.planos = await this.apiService.get(ApiService.URL + 'coinbuy/planos').toPromise();
    this.formatPlanos();
  }

  comprar(plano:Plano) {
    // this.coinBuyService.createCheckoutPlan(this.user, plano);
    localStorage.setItem('plano', JSON.stringify(plano))
    this.routes.navigate(['checkout/plano']);
  }

  formatPlanos(){
    for(let plano of this.planos){
      this.formatNamePlano.push(plano.nome.replace('Plano ',''));
    }
  }

    protected readonly UtilsModule = UtilsModule;
}
