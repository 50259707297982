import { Component } from '@angular/core';
import {UserLogged} from "../../../services/user-logged";
import {LoginComponent} from "../../modais/login/login.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {MapaComponent} from "../mapa/mapa.component";
import {User} from "../../../models/user/user";
import {NgToastService} from "ng-angular-popup";
import {Router} from "@angular/router";

@Component({
  selector: 'app-controle-feed',
  templateUrl: './controle-feed.component.html',
  styleUrls: ['./controle-feed.component.scss', '../../../../darkmode.scss']
})
export class ControleFeedComponent {

  public currentFilter:string="todos";
  public user:User;
  public isLogged:boolean=false;
  public bsModalRef: BsModalRef | undefined;

  constructor(
    private modalService: BsModalService,
    private toast:NgToastService,
    private userLogged:UserLogged,
    private routes:Router
  ) {
    this.isLogged = userLogged.isLogged;
    this.user = userLogged.user;
    this.currentFilter = <string> localStorage.getItem("typeFilter");
    if(this.currentFilter == null || this.currentFilter == "")
      this.currentFilter = "todos";
  }
  filterByImage() {
    if(this.userLogged.isLogged){
      localStorage.setItem("typeFilter", "imagem");
      window.location.href = this.routes.createUrlTree(['/'], {queryParams: {view: "imagens"}}).toString();
    } else {
      this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true},);
    }

  }

  filterByAll() {
    if(this.userLogged.isLogged){
      localStorage.setItem("typeFilter", "");
      window.location.href = this.routes.createUrlTree(['/'], {queryParams: {view: "todos"}}).toString();
    } else {
      this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true},);
    }
  }

  filterByCity() {
    if(this.userLogged.isLogged){
      if(!this.isLogged) {
        this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true});
      } else {
        this.bsModalRef = this.modalService.show(MapaComponent);
      }
    } else {
      this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true},);
    }
  }

  filterByDate() {
    if(this.userLogged.isLogged){
      localStorage.setItem("typeFilter", "novos");
      window.location.href = this.routes.createUrlTree(['/'], {queryParams: {view: "novos"}}).toString();
    } else {
      this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true},);
    }

  }

  filterByHabilidades() {
    if(!this.isLogged) {
      this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true});
    } else {
      if(this.user.habilidades.length > 0) {
        localStorage.setItem("typeFilter", "habilidade");
        window.location.href = this.routes.createUrlTree(['/'], {queryParams: {view: "habilidades"}}).toString();
      } else {
        this.toast.info({
          detail: 'INFO',
          summary: 'Necessário selecionar suas habilidades.',
          duration: 5000,
          position: 'tr'
        });
        this.routes.navigate(["perfil/edit"]);
      }
    }
  }
}
