import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../models/user/user";
import {Categoria} from "../../../models/categoria/categoria";
import {UserLogged} from "../../../services/user-logged";
import {LoginService} from "../../../services/login.service";
import {CategoriaService} from "../../../services/categoria.service";
import {UserHabilidade} from "../../../models/user/userHabilidade";
import {UserHabilidadeForm} from "../../../models/user/userHabilidadeForm";
import {NgToastService} from "ng-angular-popup";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-modal-habilidades',
  templateUrl: './modal-habilidades.component.html',
  styleUrls: ['./modal-habilidades.component.scss']
})
export class ModalHabilidadesComponent implements OnInit {

  public user: User;
  public habilidades: Categoria[] = new Array<Categoria>();
  public habilidadesFilter: Categoria[] = new Array<Categoria>();
  public searchHabilidades: string = "";

  constructor(
    private userLogged: UserLogged,
    private loginService: LoginService,
    private categoriaService: CategoriaService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
  ) {
    this.user = userLogged.user;
  }

  ngOnInit(): void {
    this.loadCategorias();
  }

  filterHabilidades() {
      this.habilidadesFilter = this.habilidades.filter(h => h.nome.toLowerCase().includes(this.searchHabilidades.toLowerCase()));
  }

  async loadCategorias() {
    this.habilidades = await this.categoriaService.getByNivel(2).toPromise();
  }

  removeHabilidade(h: UserHabilidade) {
    let uhForm: UserHabilidadeForm = {categoriaId: h.categoria.id, userId: this.user.id, add: false};
    this.user.habilidades = this.user.habilidades.filter(h2 => h2 != h);
    this.userLogged.user = this.user;
    this.loginService.updateUserHabilidade(uhForm);
  }

  adicionarHabilidade(habilidade: Categoria) {
    let uhForm: UserHabilidadeForm = {categoriaId: habilidade.id, userId: this.user.id, add: true};
    let uh: UserHabilidade = {categoria: habilidade};
    this.user.habilidades.push(uh);
    this.userLogged.user = this.user;
    this.loginService.updateUserHabilidade(uhForm);
  }

  checkIfExist(categoria: Categoria) {
    let exist = this.user.habilidades.filter(h => h.categoria.id == categoria.id);
    if (exist.length > 0)
      return false;
    else
      return true;
  }

  closeModal() {
    this.bsModalRef.hide();
    window.location.reload();
  }

  protected readonly onclick = onclick;
}
