import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {UserLogged} from "../../services/user-logged";
import {User} from "../../models/user/user";
import {LoginService} from "../../services/login.service";
import {Subject} from "rxjs";
import {CoinBuy} from "../../models/coinBuy";
import {environment} from "../../environments/environment";
import {loadStripe} from "@stripe/stripe-js";
import {NgToastService} from "ng-angular-popup";
import {HttpClient} from "@angular/common/http";
import {CategoriaService} from "../../services/categoria.service";
import {Categoria} from "../../models/categoria/categoria";
import {UserHabilidade} from "../../models/user/userHabilidade";
import {UserModel} from "../../models/user/userModel";
import {UserHabilidadeForm} from "../../models/user/userHabilidadeForm";
import {PaymentService} from "../../services/payment.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ConfirmCancelPlanComponent} from "../modais/confirm-cancel-plan/confirm-cancel-plan.component";
import {UtilsModule} from "../../utils";
import {PlanoModel} from "../../models/planoModel";
import {Plano} from "../../models/plano";


@Component({
  selector: 'app-carteira',
  templateUrl: './carteira.component.html',
  styleUrls: ['./carteira.component.scss', '../../../darkmode.scss']
})
export class CarteiraComponent {

  public user: User = new UserModel();
  // public planos: Subject<Plano[]> = new Subject<Plano[]>();
  public planos: Plano[] = new Array(new PlanoModel());

  public showPlans: boolean = true;
  public isCarteira: boolean = this.routes.url.includes('/carteira');
  stripePromise = loadStripe(environment.stripe);
  newPasswordConfirm: string = "";
  public bsModalRef: BsModalRef | undefined;

  public estadoPerfil = true;
  public estadoPerfil2 = false;

  constructor(
    public routes: Router,
    private activatedRoute: ActivatedRoute,
    public apiService: ApiService,
    private loginService: LoginService,
    private userLogged: UserLogged,
    private toast: NgToastService,
    private http: HttpClient,
    private categoriaService: CategoriaService,
    private coinBuyService: PaymentService,
    private modalService: BsModalService,
  ) {
    this.loadPlanos();
    this.user = userLogged.user;
    if (this.user.plano != null)
      this.showPlans = false;
  }

  async loadPlanos() {
    this.planos = await this.apiService.get(ApiService.URL + 'coinbuy/planos').toPromise();
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e: any) {
    // @ts-ignore
    this.user.picture = 'data:image/png;base64,' + btoa(e.target.result);
  }

  salvarUsuario() {
    if (this.user.password != null) {
      if (this.user.password == this.newPasswordConfirm) {
        this.loginService.updateUser(this.user);
        this.toast.success({
          summary: 'Informações Atualizadas e senha modificada.',
          duration: 5000,
          position: 'tr'
        });
      } else {
        this.toast.warning({
          summary: 'Senhas Incompatíveis',
          duration: 5000,
          position: 'tr'
        });
      }
    } else {
      this.loginService.updateUser(this.user);
      this.toast.success({
        summary: 'Informações Atualizadas',
        duration: 5000,
        position: 'tr'
      });
    }
  }

  buscarCep(cep: string) {
    this.http.get(`https://viacep.com.br/ws/${cep}/json/`).subscribe((c: any) => {
      if (c.logradouro != undefined) {
        this.user.cidade = c.localidade;
        this.user.estado = c.uf;
        this.user.endereco = c.logradouro + " " + c.complemento + " " + c.bairro;
      } else {
        this.toast.warning({
          summary: 'Não conseguimos identificar seu endereço.',
          duration: 5000,
          position: 'tr'
        });
      }
    });
    // @ts-ignore
    let latlong = getLatLong(cep);
    alert(latlong);
  }

  checkIfExist(categoria: Categoria) {
    let exist = this.user.habilidades.filter(h => h.categoria.id == categoria.id);
    if (exist.length > 0)
      return false;
    else
      return true;
  }

  removeHabilidade(h: UserHabilidade) {
    let uhForm: UserHabilidadeForm = {categoriaId: h.categoria.id, userId: this.user.id, add: false};
    this.user.habilidades = this.user.habilidades.filter(h2 => h2 != h);
    this.loginService.updateUserHabilidade(uhForm);
  }

  adicionarHabilidade(habilidade: Categoria) {
    let uhForm: UserHabilidadeForm = {categoriaId: habilidade.id, userId: this.user.id, add: true};
    let uh: UserHabilidade = {categoria: habilidade};
    this.user.habilidades.push(uh);
    this.loginService.updateUserHabilidade(uhForm);
  }

  altEstadoPerfil() {
    this.estadoPerfil2 = false;
    this.estadoPerfil = false;
  }


  altEstadoCarteira() {
    if (this.routes.url.includes('edit')) {
      this.estadoPerfil2 = true;
    } else {
      this.estadoPerfil = !this.estadoPerfil;
    }
  }

  verificarURL() {
    if (this.routes.url.includes('edit')) {
      return this.estadoPerfil2;
    } else {
      return this.estadoPerfil;

    }
  }

  confirmCancelPlan() {
    this.bsModalRef = this.modalService.show(ConfirmCancelPlanComponent);
  }

  reativarPlano() {
    this.coinBuyService.reativarPlano(this.user).subscribe(user => {
      this.user = user;
      localStorage.setItem("user", JSON.stringify(this.user));
      window.location.reload();
    });
  }

  comprar(plano: Plano) {
    this.coinBuyService.createCheckoutPlan(this.user, plano);
    // localStorage.setItem('plano', JSON.stringify(plano))
    // this.routes.navigate(['checkout/plano']);
  }


  protected readonly alert = alert;
  protected readonly UtilsModule = UtilsModule;
  protected readonly UserLogged = UserLogged;
}
