import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public promptEvent:any;
  constructor() {}

  public installPWA() {
    this.promptEvent.prompt();

    window.addEventListener('appinstalled', () => {
      this.promptEvent = null;
      window.location.reload();
    });
  }

  public shouldInstall() {
    return !this.isRunningStandalone();
  }

  public isRunningStandalone(): boolean {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

}
