import {Currency} from "../currency";
import {User} from "./user";
import {Plano} from "../plano";
import {UserHabilidade} from "./userHabilidade";
import {Notification} from "../notification";
import {UserCertification} from "./userCertification";
import {UserLanguage} from "./userLanguage";
import {UserExperience} from "./userExperience";
import {Payment} from "./payment";
import { Rating } from "./rating";

export class UserModel implements User {
  access!: number;
  token!: number;
  active!: boolean;
  online!: boolean;
  cep!: string;
  chave!: string;
  currency!: Currency;
  habilidades!:UserHabilidade[];
  email!: string;
  emailAllNotification!: string;
  emailNotification!: string;
  endereco!: string;
  id!: number;
  idGoogle!: string;
  keyPix!: string;
  lastActive!: Date;
  createdAt!: Date;
  latitude!: number;
  longitude!: number;
  name!: string;
  phone!: string;
  picture!: string;
  username!: string;
  plano!:Plano;
  password!:string;
  darkMode!: boolean;
  cidade!:string;
  estado!:string;
  precoHora!:number;
  area!:string;
  descricao!:string;
  prestador!:boolean;
  impressions!:number;
  countVisited!:number;
  executedProjects!:number;
  projectsInExecution!:number;
  allProjects!:number;
  validPlan!:Date;
  statusPlano!:number;
  notifications!:Notification[];
  certifications!: UserCertification[];
  languages!: UserLanguage[];
  experiences!:UserExperience[];
  payments!:Payment[];
  avaliacoes!: Rating[];
}
