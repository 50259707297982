import {ChangeDetectionStrategy, Component, HostListener} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import { PostService } from 'src/app/services/shared-post/post.service';
import {Router} from "@angular/router";
import { ControllerCarouselService } from 'src/app/services/controller-carousel.service';
import {UserLogged} from "../../services/user-logged";

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss', '../../../darkmode.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FeedComponent{

  public scrolled:boolean = false;
  categoriaNome: any;
  categoriaPaiNome: any;
  showBreadcrumb:boolean=false;
  public showNewPostButton: boolean = false;
  public subscription: Subscription = new Subscription;
  public state:Subject<boolean>;
  constructor(
    private router: Router,
    private _controllerCarouselService: ControllerCarouselService,
    private sharedService: PostService,
    private userLogged:UserLogged
      ) {
    if(localStorage.getItem("categoriaFilter") != null) {
      this.showBreadcrumb=true;
      this.categoriaNome = localStorage.getItem("categoriaNomeFilter");
      this.categoriaPaiNome = localStorage.getItem("categoriaPaiNomeFilter");
    }
    this.state = this._controllerCarouselService.state;
    this.subscription = this.sharedService.showNewPostButton$.subscribe((value) => {
      this.showNewPostButton = value;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('window:scroll', ['$event']) onScroll(e: Event): void {
    if((window.innerHeight + window.scrollY >= document.body.offsetHeight)) {
      this.scrolled = true;
    }
  }

  scrollToTop() {
    this.scrolled = false;
    window.scrollTo({top: 0});
  }

  public resetFilterAndGoHome() {
    this.userLogged.resetFilterAndGoHome();
  }

}
