import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  showNewPostButton$: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  updateShowNewPostButton(value: boolean): void {
    this.showNewPostButton$.next(value);
  }}
