import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  MeuAnuncio,
  MyAdStatus,
  MyAdsFilters,
} from '../models/anuncio/meuAnuncio';
import PageResponse from '../models/PageResponse';
import { ApiService } from './api.service';
import { UserLogged } from './user-logged';
import {User} from "../models/user/user";

interface GetByFilterParams {
  idUser: number;
  status?: MyAdStatus;
  type: string;
  title: string;
  providerName: string;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class MeuAnuncioService {
  public user: User;
  private baseUrl = `${ApiService.URL}anuncio/meus-anuncios`;

  constructor(private apiService: ApiService, private userLogged: UserLogged) {
    this.user = this.userLogged.user;
  }

  getByFilter(filters: MyAdsFilters): Observable<PageResponse<MeuAnuncio[]>> {
    const { pageMode, status, title, providerName, currentPage } = filters;

    let type = pageMode;

    const params: GetByFilterParams = {
      idUser: this.user.id,
      type,
      title,
      providerName,
      page: currentPage - 1,
    };

    if (status != undefined && !isNaN(status)) {
      params.status = +status;
    }

    return this.apiService.getParams(`${this.baseUrl}`, { params });
  }
}
