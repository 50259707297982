import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {UserLogged} from "./user-logged";
import {environment} from "../environments/environment";
import { User } from '../models/user/user';

@Injectable()
export class UserService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private httpClient: HttpClient,
              private storage:UserLogged) {
  }

  save(user: User) {
    return this.httpClient.post<User>(`${environment.apiEndpoint}user`, user,
    {
      observe: 'response'
    });
  }

  getAll() {
    return this.httpClient.get<User>(`${environment.apiEndpoint}user`,
    {
      observe: 'response'
    });
  }

  getList(page:number,itens:number, typeFilter:string, filter:any) {
    if(filter == undefined || filter == null || filter == 0)
      return this.httpClient.get<any>(`${environment.apiEndpoint}user/` + page + "/" + itens,
      {
        observe: 'response'
      });
    else
      return this.httpClient.get<any>(`${environment.apiEndpoint}user/` + page + "/" + itens + "/" + typeFilter + "/" + filter,
      {
        observe: 'response'
      });
  }

  getListWithParams(searchParams: any): Observable<any> {

    // Create HTTP parameters based on the search parameters
    let params = new HttpParams();
    params = params.append('page', searchParams.page);
    params = params.append('pageSize', searchParams.pageSize);
    params = params.append('searchTerm', searchParams.searchTerm);
    params = params.append('access', searchParams.access);
    params = params.append('active', searchParams.active);
    params = params.append('sortColumn', searchParams.sortColumn);
    params = params.append('sortDirection', searchParams.sortDirection);

    // Make the HTTP GET request with the parameters
    return this.httpClient.get<any>(`${environment.apiEndpoint}user/search/page`,
    {
      params,
      observe: 'response'
    });
  }

  getProfile(): User {
    return this.storage.getUserChat();
  }

  logout(): void{
    //this.storage.signOut()
    window.location.reload();
  }
}
