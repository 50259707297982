import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {environment} from "../environments/environment";
import {UserMessage} from "../models/user/UserMessage";
import {ApiService} from "./api.service";
import {Conversation} from "../models/chat/conversation";
import {ConversationAnexo} from "../models/chat/conversationAnexo";

@Injectable()
export class ChatService {

  constructor(private apiService: ApiService) { }

  getConversations(idUser:number, idAnuncio:number, prestador: boolean) {
    return this.apiService.get(ApiService.URL + "chat/conversation/" + idUser + "/" + idAnuncio + "/" + prestador);
  }

  getAnuncioConversations(idUser:number) {
    return this.apiService.get(ApiService.URL + "chat/anuncio_conversation/" + idUser);
  }

  async getConversation(id:number) {
    return await this.apiService.get(ApiService.URL + "conversation/" + id).toPromise();
  }

  getChat(conversation: Conversation) {
    return this.apiService.get(ApiService.URL + "chat/" + conversation.id);
  }

  deleteAnexo(id: number) {
    this.apiService.get(ApiService.URL + "proposta/anexo/" + id).toPromise();
  }

  saveAnexo(anexo: ConversationAnexo, id: number) {
    return this.apiService.post(ApiService.URL + "conversation/anexo/" + id, anexo);
  }
}
