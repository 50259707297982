import {Categoria} from "../categoria/categoria";

export interface Language {
  id: number;
  name: string;
}

export function getLanguages() {
  let languages:Language[] = new Array<Language>();
  languages.push({id: 1, name: "Português"});
  languages.push({id: 2, name: "Inglês"});
  languages.push({id: 3, name: "Espanhol"});
  languages.push({id: 4, name: "Alemão"});
  languages.push({id: 5, name: "Chinês"});
  return languages;
}
export function getLevelLanguages() {
  let languages:Language[] = new Array<Language>();
  languages.push({id: 1, name: "Básico A1 (Iniciante)"});
  languages.push({id: 2, name: "Básico A2 (Intermediário)"});
  languages.push({id: 3, name: "Intermediário B1 (Nível mínimo)"});
  languages.push({id: 4, name: "Intermediário B2 (Nível avançado)"});
  languages.push({id: 5, name: "Avançado C1 (Autônomo)"});
  languages.push({id: 5, name: "Avançado C2 (Fluente)"});
  return languages;
}
