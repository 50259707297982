import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {ChatService} from "../../services/chat.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WebSocketAPI} from "../../services/WebSocketAPI";
import {ChatDTO} from "../../models/chat/chatDTO";
import {ChatForm} from "../../models/chat/chatForm";
import {Conversation} from "../../models/chat/conversation";
import {User} from "../../models/user/user";
import {UserLogged} from "../../services/user-logged";
import {UserModel} from "../../models/user/userModel";
import {UtilsModule} from "../../utils";
import {interval} from "rxjs";
import {AnuncioFeed} from "../../models/anuncio/anuncioFeed";
import {AnuncioService} from "../../services/anuncio.service";
import {AnuncioFeedModel} from "../../models/anuncio/anuncioFeedModel";
import {AnuncioConversation} from "../../models/chat/anuncioConversation";
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss', '../../../darkmode.scss']
})
export class InboxComponent implements OnInit {

  public filteredAnuncioConversations:AnuncioFeed[] = new Array<AnuncioFeed>();
  public filteredConversations:Conversation[] = new Array<Conversation>();
  public conversations:Conversation[] = new Array<Conversation>();
  public anuncioConversations:AnuncioFeed[] = new Array<AnuncioFeed>();
  public showAnuncioConversations:boolean=false;
  public user:User;
  public selectedUser:number=0;
  // @ts-ignore
  public conversation:Conversation;
  // public sectionFilter:number = 1;
  public tittleConversation:string = 'Preciso de um desenvolvedor experiente para o desenvolvimento da minha aplicação';
  filterConversation:string="";
  public totalUnread:number=0;
  public anuncio:AnuncioFeed = new AnuncioFeedModel();
  public idAnuncio = 0;
  public url = this.router.url;

  constructor(
    private chatService: ChatService,
    public router: Router,
    private activatedRouter:ActivatedRoute,
    private userLogged:UserLogged,
    private webSocket:WebSocketAPI,
    private anuncioService:AnuncioService,
    private loginService:LoginService
  ) {
    this.user = this.userLogged.user;
    this.totalUnread = parseInt(<string> localStorage.getItem("unreadMessages"));
    this.conversation = JSON.parse(<string>localStorage.getItem("currentConversation"));
    if(isNaN(this.totalUnread))
      this.totalUnread = 0;
  }


  ngOnInit() {
    // @ts-ignore
    let url = this.activatedRouter.snapshot._routerState.url;
    if(url.includes("chat/")) {
      this.webSocket.conversation.subscribe(c => {
        this.conversation = c;
        this.selectedUser = this.user.id == this.conversation.user1Id ? this.conversation.user2Id : this.conversation.user1Id;
        // localStorage.setItem("currentConversation", JSON.stringify(c));
      });
    } else {
      localStorage.removeItem("currentConversation");
      localStorage.removeItem("proposta");
    }
    if(url.includes("anuncio")) {
      let partes = url.split("/");
      this.idAnuncio = partes[partes.length-1];
    }
    if(this.idAnuncio == 0 && !this.user.prestador) {
      this.showAnuncioConversations = true;
      this.chatService.getAnuncioConversations(this.user.id).subscribe(c => {
        this.anuncioConversations = c;
        this.filteredAnuncioConversations = this.anuncioConversations;
        // this.openConversation(c[0]);
      });
    } else {
      this.showAnuncioConversations = false;
      this.chatService.getConversations(this.user.id, this.idAnuncio, this.user.prestador).subscribe(c => {
        if (this.conversations != c) {
          this.conversations = c;
          this.filteredConversations = this.conversations;
          localStorage.removeItem("currentConversation");
          // this.openConversation(c[0]);
        }
      });
    }
    this.webSocket.conversations.subscribe(c => {
      if(this.conversations != c) {
        this.conversations = c;
        this.filteredConversations = this.conversations;
      }
    });
    this.webSocket._connectConversations();
    setTimeout(() => {
      interval(50000).subscribe((val:number) => {
        this.webSocket.checkNewConversations(this.user.id, this.idAnuncio);
      });
    },1000);
  }

  filterConversations() {
    if(this.showAnuncioConversations)
      this.filteredAnuncioConversations = this.filteredAnuncioConversations.filter(c => {
          return c.titulo.toLowerCase().includes(this.filterConversation.toLowerCase());
      });
    else
      this.filteredConversations = this.conversations.filter(c => {
        let name = (c.user1Name == this.user.name ? c.user2Name : c.user1Name)
          return name.toLowerCase().includes(this.filterConversation.toLowerCase());
      });
  }

  async openConversation(c: Conversation) {
    this.conversation = c;
    if(this.totalUnread > 0) {
      this.webSocket.unreadMessages.next(this.totalUnread - c.unread);
    }
    c.unread = 0;
    if(c.anuncio != null)
      this.anuncio = await this.anuncioService.get(c.anuncio.id);
    this.selectedUser = this.user.id == c.user1Id ? c.user2Id : c.user1Id;
    let userName = c.user1Id == this.user.id ? c.user2Name : c.user1Name
    this.webSocket.conversation.next(c);
    localStorage.setItem("currentConversation", JSON.stringify(c));
    this.router.navigate(['inbox/chat/'+UtilsModule.accentsTidy(userName)]);
  }

  removeChat() {
    localStorage.removeItem("currentConversation");
    this.anuncio = new AnuncioFeedModel();
    this.router.navigate(['/anuncio/meus-anuncios']);
  }

  openAnuncioConversation(ac: AnuncioFeed) {
    window.location.href = '/inbox/anuncio/' + ac.id;
  }

  backToInbox() {
    window.location.href = '/inbox/chat';
  }

  verifyURL(): boolean{
    let retorno = true;
    if(window.location.href.includes('/inbox/chat/')){
      retorno = false;
    }

    return retorno;
  }

  changePerfil() {
    this.user = JSON.parse(<string>localStorage.getItem("user"));
    this.user.prestador = !this.user.prestador;
    this.userLogged.user = this.user;
    localStorage.setItem("user", JSON.stringify(this.user));
    this.loginService.updateUser(this.user);
    window.location.reload();
    window.location.href = 'inbox/chat';
  }
}
