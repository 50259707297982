import { Component } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {AnuncioService} from "../../../services/anuncio.service";
import {UserLogged} from "../../../services/user-logged";
import {User} from "../../../models/user/user";
import {UserModel} from "../../../models/user/userModel";
import {UserService} from "../../../services/user.service";
import {HttpClient} from "@angular/common/http";
import {NgToastService} from "ng-angular-popup";

@Component({
  selector: 'app-update-endereco',
  templateUrl: './update-endereco.component.html',
  styleUrls: ['./update-endereco.component.scss']
})
export class UpdateEnderecoComponent {

  public user:User = new UserModel();

  constructor(
    public bsModalRef:BsModalRef,
    public userLogged:UserLogged,
    private http: HttpClient,
    private toast: NgToastService,
    public userService:UserService
  ){
    this.user = userLogged.user;
  }

  fechaModal(){
    this.bsModalRef.hide();
  }

  updateEndereco() {
    this.userService.save(this.user).subscribe(u => {
      localStorage.setItem("user", JSON.stringify(this.user));
      window.location.reload();
    });
  }

  buscarCep(cep: string) {
    this.http.get(`https://viacep.com.br/ws/${cep}/json/`).subscribe((c: any) => {
      if (c.logradouro != undefined) {
        this.user.cidade = c.localidade;
        this.user.estado = c.uf;
        this.user.endereco = c.logradouro + " " + c.complemento + " " + c.bairro;
      } else {
        this.toast.warning({
          summary: 'Não conseguimos identificar seu endereço.',
          duration: 5000,
          position: 'tr'
        });
      }
    });
    // @ts-ignore
    let latlong = getLatLong(cep);
    alert(latlong);
  }
}
