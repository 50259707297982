import { Component } from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {LoginComponent} from "../../modais/login/login.component";
import {CadastroComponent} from "../../modais/cadastro/cadastro.component";
import {AppComponent} from "../../../app.component";
import {DownloadappComponent} from "../../modais/downloadapp/downloadapp.component";
import {Router} from "@angular/router";
import {UtilsModule} from "../../../utils";

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss']
})
export class HamburgerMenuComponent {
  private bsModalRef: BsModalRef | undefined;


  constructor(
    private bsModalService: BsModalService,
    public routes:Router,
  ) {

  }

  public stateMenu:boolean = false;
  public changeState():void {
    this.stateMenu = !this.stateMenu;
    // this.stateMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto';
  }


  openModalLogin() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.bsModalService.show(LoginComponent, {backdrop:true,});
  }

  openModalCadastro(){
    this.bsModalRef?.hide();
    this.bsModalRef = this.bsModalService.show(CadastroComponent, {backdrop : true,});
  }

  openModalApp() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.bsModalService.show(DownloadappComponent, {backdrop: true,});
  }

  protected readonly UtilsModule = UtilsModule;
}
