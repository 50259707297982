import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {User} from "../../../models/user/user";
import {UserLogged} from "../../../services/user-logged";
import {LoginComponent} from "../../modais/login/login.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-aside-welcome',
  templateUrl: './aside-welcome.component.html',
  styleUrls: ['./aside-welcome.component.scss', '../../../../darkmode.scss']
})
export class AsideWelcomeComponent {

  public selector:boolean = false;
  public user:User;
  public isLogged:boolean = false;
  private bsModalRef: BsModalRef | undefined;

  constructor(
    public routes:Router,
    private modalService:BsModalService,
    private userLogged:UserLogged
  ) {
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;
  }

  public openModalLogin() {
    this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true});
  }

 /* public itemAsideColapsar = [
    {
      icone: 'fa-solid fa-arrow-up-right-dots',
      titulo: 'Leilões de Serviços Populares',
      subitems: [
        'Assistência Técnica',
        'Aulas',
        'Autos',
        'Casa',
        'Carros',
        'Computadores',
      ]
    },
    {
      icone: 'fa-solid fa-people-roof',
      titulo: 'Eventos',
      subitems: ['Assistência Técnica', 'Aulas', 'Autos', 'Casa', 'Carros', 'Computadores',]
    },
    {
      icone: 'fa-solid fa-newspaper',
      titulo: 'Notícias',
      subitems: ['Assistência Técnica', 'Aulas', 'Autos', 'Casa', 'Carros', 'Computadores',]
    },
    {
      icone: 'fa-solid fa-heart',
      titulo: 'Saúde & Fitness',
      subitems: ['Assistência Técnica', 'Aulas', 'Autos', 'Casa', 'Carros', 'Computadores',]
    },
    {
      icone: 'moda-e-beleza-icon',
      titulo: 'Moda & Beleza',
      subitems: ['Assistência Técnica', 'Aulas', 'Autos', 'Casa', 'Carros', 'Computadores',]
    },
    {
      icone: 'fa-solid fa-ranking-star',
      titulo: 'Rank',
      subitems: ['Assistência Técnica', 'Aulas', 'Autos', 'Casa', 'Carros', 'Computadores',]
    },
    {
      icone: 'fa-solid fa-list-ul',
      titulo: 'Categorias',
      subitems: ['Assistência Técnica', 'Aulas', 'Autos', 'Casa', 'Carros', 'Computadores',]
    },

  ]
  */


}
