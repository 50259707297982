import {Component, OnInit} from '@angular/core';
import {UtilsModule} from "../../../utils";
import {Dashboard} from "../../../models/admin/dashboard";
import {UserLogged} from "../../../services/user-logged";
import {User} from "../../../models/user/user";
import {DashboardModel} from "../../../models/admin/dashboardModel";
import {AdminService} from "../../../services/admin.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss', '../../../../darkmode.scss']
})
export class DashboardComponent implements OnInit {

  // @ts-ignore
  public dashboard:Dashboard = new DashboardModel();
  protected readonly UtilsModule = UtilsModule;
  public user:User;

  constructor(
    private userLogged:UserLogged,
    private adminService:AdminService
  ) {
    this.user = userLogged.user;
    if(this.user.access != 1)
      window.location.href = "/";
  }

  public ngOnInit() {
    this.adminService.getDashboard().subscribe(d => {this.dashboard = d});
  }
}
