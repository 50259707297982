import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ControllerCarouselService {

  public state = new Subject<boolean>();

  changeState(newState:boolean){
    return this.state.next(newState);
  }


}
