import {Component, Input, OnInit, Output} from '@angular/core';
import {UserHabilidade} from "../../../models/user/userHabilidade";
import {UserHabilidadeForm} from "../../../models/user/userHabilidadeForm";
import {Categoria} from "../../../models/categoria/categoria";
import {User} from "../../../models/user/user";
import {LoginService} from "../../../services/login.service";
import {CategoriaService} from "../../../services/categoria.service";
import {UserLogged} from "../../../services/user-logged";

@Component({
  selector: 'app-add-habilidades',
  templateUrl: './add-habilidades.component.html',
  styleUrls: ['./add-habilidades.component.scss']
})
export class AddHabilidadesComponent{

}
