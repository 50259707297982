import { Component } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Categoria} from "../../../../models/categoria/categoria";
import {CategoriaModel} from "../../../../models/categoria/categoriaModel";
import {CategoriaService} from "../../../../services/categoria.service";
import {NgToastService} from "ng-angular-popup";

@Component({
  selector: 'app-categoria-form',
  templateUrl: './categoria-form.component.html',
  styleUrls: ['./categoria-form.component.scss', '../../../../../darkmode.scss']
})
export class CategoriaFormComponent {

  public categoria:Categoria = new CategoriaModel();
  filhaNova: string = "";

  constructor(
    public bsModalRef:BsModalRef,
    private categoriaService:CategoriaService,
    private toast:NgToastService
  ) {
    this.categoria = <Categoria> JSON.parse(<string> localStorage.getItem("editCategory"));
    if(this.categoria == null) {
      this.categoria = new CategoriaModel();
    }
  }

  async saveCategory(category:Categoria) {
    let categoria = await this.categoriaService.save(category);
    this.bsModalRef.hide();
  }

  async removeCategoriaFilha(filha:Categoria) {
    try {
      let res = await this.categoriaService.delete(filha.id);
      this.categoria.filhas = this.categoria.filhas.filter(f => f.id != filha.id);
    } catch (e) {
      this.toast.warning({
        summary: "Não foi possível excluir categoria, já está sendo utilizada.",
        duration: 5000,
        position: 'tr'
      });
    }
  }

  async insertNewCategoriaFilha() {
    let newFilha:Categoria = new CategoriaModel();
    newFilha.nome = this.filhaNova;
    newFilha.categoriaPaiId = this.categoria.id;
    newFilha.nivel = (parseInt(this.categoria.nivel.toString()) + 1);
    newFilha = await this.categoriaService.save(newFilha);
    if(this.categoria.filhas == null)
      this.categoria.filhas = new Array<Categoria>();
    this.categoria.filhas.push(newFilha);
    this.filhaNova = "";
  }

  async updateCategoriaFilha(newFilha:Categoria) {
    newFilha = await this.categoriaService.save(newFilha);
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e:any) {
    // @ts-ignore
    this.categoria.icone = 'data:image/png;base64,' + btoa(e.target.result);
  }
}
