import {UserCertification} from "./userCertification";

export class UserCertificationModel implements UserCertification {
  id!: number;
  name!: string;
  organization!: string;
  month!: number;
  year!: number;
  url!: string;
  userId!:number;
}
