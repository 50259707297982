import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {environment} from "../environments/environment";
import {Plano} from "../models/plano";
import {User} from "../models/user/user";
import {CoinBuy} from "../models/coinBuy";
import {loadStripe} from "@stripe/stripe-js";
import {Proposta} from "../models/anuncio/proposta";
import {AnuncioFeed} from "../models/anuncio/anuncioFeed";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private url = ApiService.URL + "coinbuy/";
  stripePromise = loadStripe(environment.stripe);
  public elements:any;
  public emailAddress = '';

  constructor(
    private service: ApiService
  ) { }

  public getList() {
    return this.service.get(this.url);
  }
  public getOne(id:number) {
    return this.service.get(this.url + id);
  }

  public async createCheckoutCoin(user:User, coin:CoinBuy) {
    // here we create a payment object
    const payment = {
      name: coin.title,
      currency: 'brl',
      // amount on cents *10 => to be on dollar
      amount: coin.realValue * 100,
      quantity: '1',
      cancelUrl: environment.appURL,
      successUrl: environment.appURL + "carteira?updateCoins=true",
      user: user.id,
      typePayment: 1,
      coins: coin.valueCoin
    };

    const stripe = await this.stripePromise;

    this.service.post(`${environment.apiEndpoint}payment`, payment)
      .subscribe((data: any) => {
        // @ts-ignore
        stripe.redirectToCheckout({
          sessionId: data.id,
        });
      });
  }

  public async createCheckoutPlan(user:User, plano:Plano) {
    const payment = {
      name: plano.nome,
      currency: 'brl',
      // amount on cents *10 => to be on dollar
      amount: plano.valor * 100,
      quantity: '1',
      cancelUrl: environment.appURL,
      successUrl: environment.appURL + "carteira?updateCoins=true",
      user: user.id,
      typePayment: 2,
      coins: plano.valorTippz,
      idPlano: plano.id
    };

    const stripe = await this.stripePromise;

    const response = await this.service.post(`${environment.apiEndpoint}payment/plan`, payment).toPromise();
    let clientSecret = response.clientSecret;
    const appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: '#97c116',
      },
    };

    // @ts-ignore
    this.elements = stripe.elements({ appearance, clientSecret });

    const linkAuthenticationElement = this.elements.create("linkAuthentication");
    linkAuthenticationElement.mount("#link-authentication-element");

    linkAuthenticationElement.on('change', (event:any) => {
      this.emailAddress = event.value.email;
    });

    const paymentElementOptions = {
      layout: "tabs",
    };

    const paymentElement = this.elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
    paymentElement.on('change', (event:any) => {
      console.log(event);
    })
  }

  public cancelPlan(user:User) {
    return this.service.get(`${environment.apiEndpoint}payment/cancelplan/` + user.id);
  }

  public reativarPlano(user:User) {
    return this.service.get(`${environment.apiEndpoint}payment/reativarplan/` + user.id);
  }

  async createCheckoutProposta(user:User, proposta: Proposta) {
    // here we create a payment object
    const payment = {
      name: proposta.anuncio.titulo,
      currency: 'brl',
      // amount on cents *10 => to be on dollar
      amount: (proposta.valor + (proposta.valor * 0.05)) * 100,
      quantity: '1',
      cancelUrl: environment.appURL + "inbox/chat",
      successUrl: environment.appURL + "inbox/chat",
      user: user.id,
      typePayment: 3,
      idProposta: proposta.id,
      coins: 0
    };

    const stripe = await this.stripePromise;

    const response = await this.service.post(`${environment.apiEndpoint}payment`, payment).toPromise();
    let clientSecret = response.clientSecret;
    const appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: '#97c116',
      },
    };

    // @ts-ignore
    this.elements = stripe.elements({ appearance, clientSecret });

    const linkAuthenticationElement = this.elements.create("linkAuthentication");
    linkAuthenticationElement.mount("#link-authentication-element");

    linkAuthenticationElement.on('change', (event:any) => {
      this.emailAddress = event.value.email;
    });

    const paymentElementOptions = {
      layout: "tabs",
    };

    const paymentElement = this.elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
  }

  async confirmPayment() {
    const stripe = await this.stripePromise;
    let elements = this.elements;
    // @ts-ignore
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: environment.appURL + "carteira?updateCoins=true",
        receipt_email: this.emailAddress,
      },
    });
  }
}
