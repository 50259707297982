import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() totalPages: number = 1;
  @Input() currentPage: number = 1;
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();

  selectPage(page: number) {
    this.changePage.emit(page);
  }

  getRange(num: number): number[] {
    return Array.from({length: num}, (_, index) => index + 1);
  }
}
