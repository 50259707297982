import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AnuncioService } from 'src/app/services/anuncio.service';
import { ModalCancelaAnuncioComponent } from '../modal-cancela-anuncio/modal-cancela-anuncio.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MeuAnuncio } from 'src/app/models/anuncio/meuAnuncio';
import {User} from "../../../models/user/user";

@Component({
  selector: 'app-post-menu',
  templateUrl: './post-menu.component.html',
  styleUrls: ['./post-menu.component.scss'],
})
export class PostMenuComponent {
  @Input() showMenu = false;
  @Input() anuncio: MeuAnuncio | undefined;

  public user: User;
  private bsModalRef: BsModalRef | undefined;

  constructor(
    private anuncioService: AnuncioService,
    private modalService: BsModalService,
    public router: Router,
  ) {
    // @ts-ignore
    this.user = JSON.parse(localStorage.getItem('user')) || {};
  }

  mudaStatus() {
    this.anuncioService.mudaPrivado(this.anuncio!.id);
    this.anuncio!.privado = !this.anuncio?.privado;
  }

  openModalCancela() {
    this.bsModalRef = this.modalService.show(ModalCancelaAnuncioComponent, {
      backdrop: true,
    });
    this.bsModalRef.content.content = this.anuncio;
  }

  edit(): void {
    const data = {
      idAnuncio: this.anuncio?.id,
    };
    this.router.navigate(['/anuncio/editar'], { state: data });
  }


  mudaStatusParaAtivo() {
    this.anuncioService.mudaStatus(this.anuncio!.id, 0, '');
    this.anuncio!.status = 0;
  }
}
