import { Component, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-logo-slider',
  templateUrl: './logo-slider.component.html',
  styleUrls: ['./logo-slider.component.scss']
})
export class LogoSliderComponent implements OnInit {
  @Input() logos: string[] = [];
  @Input() carouselWidth: number = 0;
  @Input() animationDuration: number = 120;
  @Input() title?: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setAnimation();
  }

  private setAnimation(): void {
    const sliderElement = this.el.nativeElement.querySelector('.slider');

    const keyframes = `
      @keyframes slidein {
        from {
          transform: translate3d(0, 0, 0);
        }
        to {
          transform: translate3d(-${this.carouselWidth}px, 0, 0);
        }
      }
    `;

    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = keyframes;
    document.head.appendChild(styleSheet);

    this.renderer.setStyle(sliderElement, 'animation', `slidein ${this.animationDuration}s linear infinite`);
  }
}
