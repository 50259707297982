import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-modal-perguntas',
  templateUrl: './modal-perguntas.component.html',
  styleUrls: ['./modal-perguntas.component.scss']
})
export class ModalPerguntasComponent {

  constructor(
    public bsModalRef:BsModalRef,
    private routes:Router,
  ) {}

  public perguntas = [
    { id: 123, user: 'Luan S.', data: new Date(), srcImg:'/assets/_img/isotipo.png' ,pergunta: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popula'},
    { id: 123, user: 'Luan S.', data: new Date(), srcImg:'/assets/_img/isotipo.png' ,pergunta: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popula'},
    { id: 123, user: 'Luan S.', data: new Date(), srcImg:'/assets/_img/isotipo.png' ,pergunta: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popula'},
    { id: 123, user: 'Luan S.', data: new Date(), srcImg:'/assets/_img/isotipo.png' ,pergunta: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popula'},
    { id: 123, user: 'Luan S.', data: new Date(), srcImg:'/assets/_img/isotipo.png' ,pergunta: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popula'},
    { id: 123, user: 'Luan S.', data: new Date(), srcImg:'/assets/_img/isotipo.png' ,pergunta: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popula'},

  ]

  redirectPergunta(){
    this.routes.navigate(['inbox/chat']);
    this.bsModalRef.hide();
  }
}
