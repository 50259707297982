import {Component, OnInit} from '@angular/core';
import {Router, RouterModule} from "@angular/router";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ModalLeilaoComponent} from "./modal-leilao/modal-leilao.component";
import {Leilao} from "../../../models/admin/leilao";
import {LeilaoService} from "../../../services/leilao.service";
import {UtilsModule} from "../../../utils";

@Component({
  selector: 'app-leiloes',
  templateUrl: './leiloes.component.html',
  styleUrls: ['./leiloes.component.scss', '../../../../darkmode.scss']
})
export class LeiloesComponent implements OnInit {

  public bsModalRef: BsModalRef | undefined;
  public leiloes:Leilao[] = new Array<Leilao>();

  constructor(
    private serviceModal: BsModalService,
    private leilaoService:LeilaoService
  ) {
  }

  ngOnInit() {
    this.leilaoService.getAll().subscribe(l => this.leiloes = l);
  }

  createLeilao(){
    this.bsModalRef = this.serviceModal.show(ModalLeilaoComponent, {backdrop:true})
  }
  openLeilao(id:number){
    this.bsModalRef = this.serviceModal.show(ModalLeilaoComponent, {backdrop:true})
  }

  encerrarLeilao(i:number){
    this.leiloes.splice(i, 1);
  }


  protected readonly UtilsModule = UtilsModule;
}
