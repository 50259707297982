import { Component } from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {UserLogged} from "../../../services/user-logged";
import { User } from 'src/app/models/user/user';
import { UserModel } from 'src/app/models/user/userModel';
import { Proposta } from 'src/app/models/anuncio/proposta';
import { PropostaModel } from 'src/app/models/anuncio/propostaModel';
import { Conversation } from 'src/app/models/chat/conversation';
import { AnuncioFeed } from 'src/app/models/anuncio/anuncioFeed';
import {NgToastService} from "ng-angular-popup";

@Component({
  selector: 'app-avaliacoes',
  templateUrl: './avaliacoes.component.html',
  styleUrls: ['./avaliacoes.component.scss', '../../../../darkmode.scss']
})
export class AvaliacoesComponent {

  public user:User = new UserModel();
  public conversation:Conversation;
  public proposta:Proposta;
  public anuncio:AnuncioFeed;
  public descricao:string = "";

  constructor(
    private apiService:ApiService,
    private userLogged: UserLogged,
    private toast:NgToastService,
  ){
    this.user = JSON.parse(<string> localStorage.getItem('user'));
    this.conversation = JSON.parse(<string> localStorage.getItem("currentConversation"));
    this.proposta = this.proposta = <Proposta> JSON.parse(<string>localStorage.getItem("proposta"));
    this.anuncio = JSON.parse(<string> localStorage.getItem('viewAnuncio'));

  }

  selectedRating = 0;
  stars = [
    {
      id: 1,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 2,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 3,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 4,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 5,
      icon: 'star',
      class: 'star-gray star-hover star'
    }

  ];

  selectStar(value : any){
    this.stars.filter( (star) => {

      if ( star.id <= value){

        star.class = 'star-gold star';

      }else{

        star.class = 'star-gray star';

      }
      return star;
    });

    this.selectedRating = value;

  }

  base64textString = [];



  onUploadChange(evt: any) {
    if(this.base64textString.length <= 4) {
      const file = evt.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    }
  }


  handleReaderLoaded(e:any) {
    // @ts-ignore
    this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));
  }

  enviarAvaliacao(){
    if(this.selectedRating === 0){
      this.toast.info({
        detail: 'INFO',
        summary: 'Escolha o nível em estrelas para fazer a avaliação.',
        duration: 5000,
        position: 'tr'
      });
      return;
    }

    let remetenteId;
    let remetenteNome;

    if(this.conversation.user1Id == this.user.id){
      remetenteId = this.conversation.user2Id
      remetenteNome = this.conversation.user1Name
    }else{
      remetenteId = this.conversation.user1Id;
      remetenteNome = this.conversation.user2Name;
    }

    if(this.selectedRating != 0){
      let avaliacoes = {
        rate: this.selectedRating,
        description: this.descricao,
        userName: remetenteNome,
        userId: remetenteId,
        imagens: this.base64textString,
        proposta: this.proposta,
        anuncio: this.anuncio
      }
      this.apiService.post(ApiService.URL + 'user/avaliacoes', avaliacoes).subscribe(resp => {
        localStorage.setItem("proposta", JSON.stringify(resp.proposta));
        this.conversation = JSON.parse(<string> localStorage.getItem("currentConversation"));
        this.conversation.proposta = resp.proposta;
        localStorage.setItem('currentConversation', JSON.stringify(this.conversation));
        window.location.reload();
      });
    }
  }
}
