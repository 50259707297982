import {Component, OnInit} from '@angular/core';
import {LoaderService} from "../../../services/loader.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss', '../../../../darkmode.scss']
})
export class LoaderComponent implements OnInit{

  public loading:boolean=false;

  constructor(
    private loaderService:LoaderService
  ) {}

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe(l => {
      this.loading = l;
    })
  }
}
