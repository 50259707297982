import { Component } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {AnuncioService} from "../../../services/anuncio.service";
import {User} from "../../../models/user/user";
import {UserLogged} from "../../../services/user-logged";
import {NgToastService} from "ng-angular-popup";

@Component({
  selector: 'app-promover-anuncio',
  templateUrl: './promover-anuncio.component.html',
  styleUrls: ['./promover-anuncio.component.scss']
})
export class PromoverAnuncioComponent {
  public content:any;
  public user:User;
  public day1:number=0;
  public day1Temp:number=0;
  public day2:number=0;
  public day2Temp:number=0;
  public valorTippz:number=0;
  constructor(
    public bsModalRef:BsModalRef,
    private anuncioService: AnuncioService,
    private userLogged:UserLogged,
    private toast:NgToastService
  ){
    this.user = userLogged.user
  }

  fechaModal(){
    this.bsModalRef.hide();
  }

  async promoverAnuncio(){
    if(this.valorTippz > 0) {
      if(this.user.currency.value >= this.valorTippz) {
        await this.anuncioService.promoverAnuncio(this.content.id, this.day1, this.day2);
        this.user.currency.value -= this.valorTippz;
        localStorage.setItem("user", JSON.stringify(this.user));
        this.fechaModal();
        window.location.reload();
      } else {
        this.toast.warning({
          summary: "Voc� precisa adquirir tippz para promover seu an�ncio.",
          duration: 5000,
          position: 'tr'
        });
      }
    } else {
      this.toast.warning({
        summary: "Informe os dois dias da semana que deseja promover o an�ncio.",
        duration: 5000,
        position: 'tr'
      });
    }
  }

  async calcularPromocao() {
    if(this.day1Temp == this.day2Temp) {
      this.toast.warning({
        summary: "Informe dias da semana diferentes para promover o an�ncio.",
        duration: 5000,
        position: 'tr'
      });
      this.day1Temp = this.day1;
      this.day2Temp = this.day2;
    } else {
      this.day1 = this.day1Temp;
      this.day2 = this.day2Temp;
      if (this.day1 != 0 && this.day2 != 0) {
        this.valorTippz = await this.anuncioService.getCurrentValuePromotion(this.day1, this.day2);
      }
    }
  }
}
