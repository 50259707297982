import {Categoria} from "../categoria/categoria";
import {Leilao} from "./leilao";

export class LeilaoModel implements Leilao {
  id!: number;
  status!: number;
  titulo!: string;
  picture!: string;
  minimumValue!: number;
  offValue!: number;
  descricao!: string;
  incrementRate!: number;
  incrementQuant!: number;
  minParticipants!: number;
  inicio!: Date;
  closure!: number;
  valueBid!: number;
  bids!: number;
  lastBid!: number;
  geolocation!: boolean;
  categoryId!: number;
  category!: Categoria;
}
