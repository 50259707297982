import {Component, Input} from '@angular/core';
import {UtilsModule} from "../../../utils";
import {Categoria} from "../../../models/categoria/categoria";
import {CategoriaModel} from "../../../models/categoria/categoriaModel";
import {FormsNovoAnuncioComponent} from "../forms-novo-anuncio/forms-novo-anuncio.component";
import {User} from "../../../models/user/user";
import {UserLogged} from "../../../services/user-logged";
@Component({
  selector: 'app-model-novo-anuncio',
  templateUrl: './model-novo-anuncio.component.html',
  styleUrls: ['./model-novo-anuncio.component.scss', '../../../../darkmode.scss']
})
export class ModelNovoAnuncioComponent {
  @Input()
  anuncio:any;
  @Input()
  habilidades:any;

  public user:User | undefined;

  @Input() categorias:Categoria[] = new Array<Categoria>();
  constructor(
    private userLogged:UserLogged,
  ) {
    this.user = this.userLogged.user;
  }

  getNomeCategoria(id: string) {
    if (id != "" && this.categorias && this.categorias.length > 0) {
      let catArray = this.categorias.filter(c => c != null && c.id == parseInt(id));
      if (catArray.length > 0) {
        let cat: Categoria = catArray[0];
        return cat.nome;
      }
    }
    return "";
  }

  ajusteTamanho(tamanho:number, valorString:string){

    if(valorString != undefined && valorString.length >= tamanho){
      let result:string = valorString.substring(0, tamanho).concat('...');
      return result;
    } else {
      return valorString;
    }

  }

  protected readonly FormsNovoAnuncioComponent = FormsNovoAnuncioComponent;

  protected readonly alert = alert;

  protected readonly console = console;
  protected readonly UtilsModule = UtilsModule;
}

