import {Component} from '@angular/core';
import {AnuncioFeed} from "../../../models/anuncio/anuncioFeed";
import {AnuncioImagem} from "../../../models/anuncio/anuncioImagem";
import {AnuncioService} from "../../../services/anuncio.service";
import {AnuncioComponent} from "../../anuncio/anuncio.component";

@Component({
  selector: 'app-image-anuncio',
  templateUrl: './image-anuncio.component.html',
  styleUrls: ['./image-anuncio.component.scss']
})
export class ImageAnuncioComponent {

  public anuncio:AnuncioFeed;
  // @ts-ignore
  public arrListaImgs:AnuncioImagem[];
  public selected:number = 0;

  constructor() {
    this.selected = parseInt(<string> localStorage.getItem("selectedImageAnuncio"));
    this.anuncio = JSON.parse(<string> localStorage.getItem("viewAnuncio"));
    if(this.anuncio != null)
      this.arrListaImgs = this.anuncio.imagensAnuncio;
  }

}
