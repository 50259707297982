import {Component} from '@angular/core';
import {CoinBuy} from "../../models/coinBuy";
import {UserLogged} from "../../services/user-logged";
import {Proposta} from "../../models/anuncio/proposta";
import {PaymentService} from "../../services/payment.service";
import {AnuncioFeed} from "../../models/anuncio/anuncioFeed";
import {User} from "../../models/user/user";
import {LoginService} from "../../services/login.service";
import {UserModel} from "../../models/user/userModel";
import {UtilsModule} from "../../utils";
import {HttpClient} from "@angular/common/http";
import {Form} from "@angular/forms";
import {Toast} from "primeng/toast";
import {NgToastService} from "ng-angular-popup";

@Component({
  selector: 'app-deposito-garantia',
  templateUrl: './deposito-garantia.component.html',
  styleUrls: ['./deposito-garantia.component.scss', '../../../darkmode.scss']
})
export class DepositoGarantiaComponent {


  public email : string = "";
  public emailValid: string = "";

  public cpf: string = "";
  public cpfValid: string = "";

  public numCartao: string = "";
  public cartaoValid: string = "";

  public cvv: string = "";
  public cvvValid: string = "";

  public vencimentoData: string = "";
  public dataValid: string = "";

  public name: string = ""
  public nameValid: string = "";

  public controlCep:boolean = false;
  public validCep: string = "";
  public cep: string = "";
  public retornoJSON: any;
  public estado: string | undefined;
  public cidade: string | undefined;
  public bairro: string | undefined;
  public rua: string | undefined;
  public numero: string | undefined;
  public complemento: string = "";

  public user;
  public prestador: User = new UserModel();
  // @ts-ignore
  public proposta: Proposta;
  // @ts-ignore
  public anuncio: AnuncioFeed;

  constructor(
    public userLogged: UserLogged,
    private paymentService: PaymentService,
    private loginService: LoginService,
    private http: HttpClient,
    private toast:NgToastService,
  ) {
    this.user = userLogged.user;
    this.getInfos();
    this.proposta = <Proposta>JSON.parse(<string>localStorage.getItem("proposta"));
    this.paymentService.createCheckoutProposta(this.user, this.proposta);
  }

  public formatDataValidade() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();

    // @ts-ignore
    let input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '')
      .replace(/(\d{2})(?=\d)/g, '$1/');

    this.vencimentoData = input.value;
    input.value = value;

    if(value.length === 0){
      this.dataValid = "invalid";
      return;

    }

    if(value.length === 5){
      const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
      if (!regex.test(value)) {
        this.dataValid = "invalid";
        return;
      }
      const month = parseInt(value.substring(0, 2));
      const year = parseInt(value.substring(3));
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() % 100;
      const currentMonth = currentDate.getMonth() + 1;

      if (year < currentYear || (year === currentYear && month < currentMonth) || month > 12) {
        this.dataValid = "invalid";
        return;
      } else{
        this.dataValid = "valid";
      }
    }
  }

  public formatCPF() {
    // @ts-ignore
    let input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '')
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    this.cpf = input.value
    input.value = value;
  }
  public verifyCPF(){
    let cpf = this.cpf;

    if (cpf.length !== 11) {
      this.cpfValid = "invalid";
      return;
    }

    if (/^(\d)\1{10}$/.test(cpf)) {
      this.cpfValid = "invalid";
      return;
    }

    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = 11 - (soma % 11);
    let digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto;

    if (parseInt(cpf.charAt(9)) !== digitoVerificador1) {
      this.cpfValid = "invalid";
      return;
    }

    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = 11 - (soma % 11);
    let digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto;

    if (parseInt(cpf.charAt(10)) !== digitoVerificador2) {
      this.cpfValid = "invalid";
      return;
    }

    this.cpfValid = "valid";
  }

  public getEmail(){
    // @ts-ignore
    let input = event.target as HTMLInputElement;
    this.email = input.value;
  }
  public validEmail(){
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if(this.email.length === 0){
      this.emailValid = 'invalid';
      return;
    }
    if(!this.email.includes('@')){
      this.emailValid = 'invalid';
      return;
    }
    if(!this.email.match(emailRegex)){
      this.emailValid = 'invalid';
    }

    else{
      this.emailValid = 'valid';
    }
  }

  public formatNumCartao() {
    // @ts-ignore
    let input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '')
      .replace(/(\d{4})(?=\d)/g, '$1 ');
    this.numCartao = input.value;
    input.value = value;
  }
  public validNumCartao(){
    const regexVisa = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const regexMastercard = /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/;
    const regexAE = /^3[47][0-9]{13}$/;
    if(regexVisa.test(this.numCartao)){
      this.cartaoValid = "visa-valid";
      return;
    }
    if(regexMastercard.test(this.numCartao)){
      this.cartaoValid = "mastercard-valid";
      return;
    }
    if(regexAE.test(this.numCartao)){
      this.cartaoValid = "american-express-valid";
      return;
    }
    if(this.numCartao && this.numCartao.length === 19){
      this.cartaoValid = "valid";
      return;
    }
    else{
      this.cartaoValid = "invalid";
      return;
    }



  }

  public formatCVV() {
    // @ts-ignore
    let input = event.target as HTMLInputElement;
    let value: string = input.value.replace(/\D/g, '')
    this.cvv = input.value;
    input.value = value;
  }
  public validCVV(){
    if(this.cvv.length < 3){
      this.cvvValid = 'invalid';
    } else{
      this.cvvValid = 'valid';
      return;
    }

  }

  public getNameCartao(){
    // @ts-ignore
    let input = event.target as HTMLInputElement;
    this.name = input.value;
  }
  public validNomeCartao(){
    const regex = /^[A-Za-z]+ [A-Za-z]+$/;
    if(!regex.test(this.nameValid) && this.nameValid.length === 0){
      this.nameValid = "invalid";
      return;
    } else{
      this.nameValid = "valid";
      return;
    }
  }

  public converterCEP(data: any) {
    this.estado = data.uf;
    this.cidade = data.localidade;
    this.bairro = data.bairro;
    this.rua = data.logradouro;
    this.numero = data.numero.toString().replace(/^[0-9]*$/,'');
    this.complemento = data.complemento;

  }
  public getCEP() {
    // @ts-ignore
    let input = event.target as HTMLInputElement;
    let value: string = input.value.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2')
    input.value = value;

    if(input.value.length === 9){
      this.validCep = "valid";
    } else{
      this.validCep = "invalid";
    }

    if(value.length === 9){
      this.cep = value.replace(/\D/g, '');
      this.retornoJSON = this.http
        .get('https://viacep.com.br/ws/' + this.cep + '/json/')
        .subscribe(data => {
          this.converterCEP(data)
        });
      this.controlCep = true;
    }

  }

  async getInfos() {
    this.proposta = <Proposta>JSON.parse(<string>localStorage.getItem("proposta"));
    this.prestador = await this.loginService.getUser(this.proposta.prestador.id);
  }

  concluirPagamento() {
    this.paymentService.confirmPayment();
  }

  protected readonly UtilsModule = UtilsModule;
}
