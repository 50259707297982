import { Component, OnInit } from '@angular/core';
import { UserLogged } from '../../../services/user-logged';
import { LoginService } from '../../../services/login.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { NgToastService } from 'ng-angular-popup';
import { LoaderService } from '../../../services/loader.service';
import { environment } from 'src/app/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {User} from "../../../models/user/user";
import {UserModel} from "../../../models/user/userModel";

interface MapForm {
  city: string
}

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
})
export class MapaComponent implements OnInit {
  public user: User = new UserModel();
  public radiusInKm: number = 20;
  public zoom: number = 10;
  public city = '';

  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private loginService: LoginService,
    private routes: Router,
    private toast: NgToastService,
    private loaderService: LoaderService,
    private userLogged: UserLogged,
    private http: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.user = userLogged.user;
    if (this.user.latitude == null) {
      this.getLocation();
    }
  }

  ngOnInit() {
    this.setLocationByUser();
    this.setAddressByLocation();
  }

  private setLocationByUser() {
    if(this.user.endereco != null)
      this.getLocationByCity();
  }

  private setAddressByLocation() {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.user.latitude},${this.user.longitude}&key=${environment.googleApiKey}`;
    this.http.get(url).subscribe((data: any) => {
      if(data.status === 'OK') {
        const result = data.results[0];
        const city = result.address_components[3].long_name;
        this.city = city;
      }
    })
  }

  changeZoom() {
    if(this.radiusInKm > 100)
      this.radiusInKm = 100;
    if(this.radiusInKm >= 20)
      this.zoom = 10 - this.radiusInKm * 0.02;
    else
      this.zoom = 12.5 + (0.1 - this.radiusInKm * 0.1);
  }

  public getLocationByCity() {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(this.user.endereco)}&key=${environment.googleApiKey}`;

    this.http.get(url).subscribe((data: any) => {
      if(data.status === 'OK') {
        const location = data.results[0].geometry.location;
        this.user.latitude = location.lat;
        this.user.longitude = location.lng;
      }
    })
  }

  public getLocation() {
    let _this = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        _this.user = JSON.parse(<string>localStorage.getItem('user'));
        _this.user.longitude = position.coords.longitude;
        _this.user.latitude = position.coords.latitude;
        localStorage.setItem('user', JSON.stringify(_this.user));
        _this.loginService.saveLatLong(_this.user);
      });
    } else {
      alert('O seu navegador não suporta Geolocalização.');
    }
  }

  public showPosition(position: any) {
  }

  filterMapa() {
    this.user = this.userLogged.user;
    this.loginService.saveLatLong(this.user);
    localStorage.setItem('typeFilter', 'cidade');
    localStorage.setItem('cidadeFilter', this.user.id.toString());
    localStorage.setItem('kmFilter', this.radiusInKm.toString());
    window.location.href = this.routes.createUrlTree(['/'], { queryParams: { view: 'distancia' } }).toString();
  }
}
