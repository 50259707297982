import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NbEvaIconsModule} from "@nebular/eva-icons";
import {ChatComponent} from "./chat/chat.component";
import {RouterOutlet} from "@angular/router";
import {InboxRoutingModule} from "./inbox-routing.module";
import {InboxComponent} from "./inbox.component";
import {FormsModule} from "@angular/forms";
import {NgbDropdown, NgbDropdownMenu, NgbDropdownToggle} from "@ng-bootstrap/ng-bootstrap";
import { PickerModule } from '@ctrl/ngx-emoji-mart';
@NgModule({
  declarations: [
    InboxComponent,
    ChatComponent,
  ],
    imports: [
        CommonModule, FormsModule, InboxRoutingModule, NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, PickerModule,
    ]
})
export class InboxModule { }
