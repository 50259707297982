
export interface UserExperience {
  id: number;
  userId: number;
  name: string;
  time: number;
}

export function getTimes() {
  let types = new Array();
  types.push({id: 1, name: "Menos de 6 Meses"});
  types.push({id: 2, name: "De 6 Meses a 1 Ano"});
  types.push({id: 3, name: "De 1 Ano a 2 Ano"});
  types.push({id: 4, name: "De 2 Meses a 3 Ano"});
  types.push({id: 5, name: "De 3 Meses a 5 Ano"});
  types.push({id: 6, name: "Mais de 5 Anos"});
  return types;
}
