import { Injectable } from '@angular/core';
import {User} from "../models/user/user";
import {Subject} from "rxjs";
import {Currency} from "../models/currency";
import {UserModel} from "../models/user/userModel";

@Injectable({
  providedIn: 'root'
})
export class UserLogged {

  public isLogged:boolean = false;
  public user:UserModel;

  constructor() {
    let user:User = JSON.parse(<string> localStorage.getItem('user')) || {name: '', picture: '/assets/_img/default_profile.png'};
    let jwt = localStorage.getItem('jwt');
    this.user = user;
    this.isLogged = jwt!=undefined;
  }

  getToken() {
    return localStorage.getItem('jwt');
  }

  getUserChat() {
    return this.user;
  }

  public resetFilterAndGoHome() {
    localStorage.removeItem("categoriaFilter");
    localStorage.removeItem("categoriaNomeFilter");
    localStorage.removeItem("tipoCategoriaFilter");
    localStorage.removeItem("searchFilter");
    localStorage.removeItem("cidadeFilter");
    localStorage.removeItem("kmFilter");
    // window.location.href = "/";
  }
}
