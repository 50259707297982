import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {UserLogged} from "../../services/user-logged";
import {User} from "../../models/user/user";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss', '../../../darkmode.scss']
})
export class AdminComponent {

  public user:User;

  constructor(
    private userLogged:UserLogged,
    private router:Router
  ) {
    this.user = userLogged.user;
    if(this.user.access == 0) {
      router.navigate(['/']);
    }
  }
}
