import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {UserLogged} from "../../services/user-logged";
import {User} from "../../models/user/user";
import {UtilsModule} from "../../utils";
import {AnuncioService} from "../../services/anuncio.service";
import {Meta, Title} from '@angular/platform-browser';
import {AnuncioFeed} from "../../models/anuncio/anuncioFeed";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ModelPerguntaComponent} from "./model-pergunta/model-pergunta.component";
import {NgToastService} from "ng-angular-popup";
import {InfosAnuncio} from "../../models/anuncio/infosAnuncio";
import {ImageAnuncioComponent} from "../modais/image-anuncio/image-anuncio.component";
import {ModalPerguntasComponent} from "../modais/modal-perguntas/modal-perguntas.component";
import {LoginComponent} from "../modais/login/login.component";

@Component({
  selector: 'app-anuncio',
  templateUrl: './anuncio.component.html',
  styleUrls: ['./anuncio.component.scss', '../../../darkmode.scss']
})
export class AnuncioComponent {


  protected readonly UtilsModule = UtilsModule;
  public anuncio: AnuncioFeed;
  // @ts-ignore
  public infosAnuncio: InfosAnuncio;
  public isLogged: boolean = false;
  public user: User;
  private bsModalRef: BsModalRef | undefined;
  scriptPage: any;
  categoriaNome: any;
  categoriaPaiNome: any;
  showBreadcrumb: boolean = false;
  public changeStateCarousel: boolean = false;
  public state:boolean = false;

  constructor(
    private routes: Router,
    private userLogged: UserLogged,
    private anuncioService: AnuncioService,
    private meta: Meta,
    private title: Title,
    private modalService: BsModalService,
    private toast: NgToastService,


  ) {
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;
    this.anuncio = JSON.parse(<string>localStorage.getItem('viewAnuncio'));
    this.getAnuncio();
  }

  async getAnuncio() {
    let idAnuncio;
    if (this.anuncio == undefined)
      idAnuncio = parseInt(this.routes.url.substring(5, this.routes.url.indexOf("-")));
    else if (this.anuncio.clienteId == undefined || !idAnuncio)
      idAnuncio = this.anuncio.id
    this.anuncio = <AnuncioFeed>await this.anuncioService.get(idAnuncio);
    this.infosAnuncio = await this.anuncioService.getInfos(this.anuncio.id);
    this.anuncio.imagensAnuncio = await this.anuncioService.getImagens(this.anuncio.id);
    this.showBreadcrumb = true;
    this.categoriaNome = this.anuncio.categoriaPrincipal.nome;
    this.categoriaPaiNome = this.anuncio.categoriaPrincipal.categoriaPaiNome;
    this.createScriptSEO();
    localStorage.setItem("viewAnuncio", JSON.stringify(this.anuncio));
    /*if(this.anuncio.imagensAnuncio.length > 0) {
      for(let ia of this.anuncio.imagensAnuncio) {
        this.meta.addTag({name:'og:image', content: ia.imagem});
      }
    } else {
      this.meta.addTag({name:'og:image', content: "favicon.ico"});
    }*/
  }

  createScriptSEO() {
    this.meta.addTag({name: 'og:title', content: this.anuncio.titulo + " Procura-se Freelancer | Tippz"});
    this.meta.addTag({name: 'title', content: this.anuncio.titulo + " Procura-se Freelancer | Tippz"});
    this.title.setTitle(this.anuncio.titulo + " Procura-se Freelancer | Tippz");
    this.meta.addTag({
      name: 'og:description',
      content: UtilsModule.removeHtmlTags(this.anuncio.descricao).substring(0, 100) + `...Procuro um Freelancer. Procuro um Prestador de Serviço. Publicado em: ${this.anuncio.categoriaPrincipal.nome}.`
    });
    this.meta.addTag({
      name: 'description',
      content: UtilsModule.removeHtmlTags(this.anuncio.descricao).substring(0, 100) + `...Procuro um Freelancer. Procuro um Prestador de Serviço. Publicado em: ${this.anuncio.categoriaPrincipal.nome}.`
    });
    this.meta.addTag({name: 'og:url', content: window.location.href});
    this.meta.addTag({name: 'og:type', content: "website"});
    this.meta.addTag({name: 'og:image', content: "favicon.ico"});
    this.meta.addTag({name: 'canonical', content: window.location.href});
    let node = document.createElement('link');
    node.rel = 'canonical';
    node.href = window.location.href;
    document.getElementsByTagName('head')[0].appendChild(node);
    let script = document.createElement('script');
    script.type = 'application/ld+json';
    script.textContent = `
        {
          "@context": "http://schema.org",
          "@type": "offers",
          "name": "${this.anuncio.titulo} | Tippz",
          "description": "${UtilsModule.removeHtmlTags(this.anuncio.descricao).substring(0, 100)}...Procuro um Freelancer. Procuro um Prestador de Serviço. Publicado em: ${this.anuncio.categoriaPrincipal.nome}.",
          "offers": {
            "@type": "Offer",
            "price": "${this.anuncio.valor}"
            "priceCurrency": "BRL"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingType": "${this.anuncio.valor}",
            "ratingCount": "1"
          },
          "image": "https://tippz.com.br/assets/_img/novo-anuncio-image.png",
          "url": "${this.routes.url}"
        }
    `;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  public resetFilterAndGoHome() {
    this.userLogged.resetFilterAndGoHome();
  }

  openModalPergunta() {
    if(this.isLogged)
      this.bsModalRef = this.modalService.show(ModelPerguntaComponent, {backdrop: true});
    else
      this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true});
  }

  openModalPerguntas(anuncio: AnuncioFeed){
    if(this.isLogged)
      this.bsModalRef = this.modalService.show(ModalPerguntasComponent, {
        backdrop: true
      })
    else
      this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true});
  }

  editProjeto(anuncio:AnuncioFeed): void{
    const data = {
      idAnuncio: anuncio.id,
    };
    this.routes.navigate(['/anuncio/editar'], { state: data });
  }

  public openNewProposta(anuncio: AnuncioFeed) {
    if(this.isLogged) {
      if (this.user.currency.value == 0) {
        this.toast.info({
          detail: 'INFO',
          summary: 'Necessário adquirir Tippz para realizar propostas.',
          duration: 5000,
          position: 'tr'
        });
      }
      if (this.user.currency.value < anuncio.categoriaPrincipal.lance) {
        this.toast.info({
          detail: 'INFO',
          summary: 'Necessário adquirir mais Tippz para realizar proposta para esse anúncio.',
          duration: 5000,
          position: 'tr'
        });
      } else {
        localStorage.setItem("viewAnuncio", JSON.stringify(anuncio));
        this.routes.navigate(['proposta/new']);
      }
    } else {
      this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true});
    }
  }

  openModalImage(i:number) {
    localStorage.setItem("selectedImageAnuncio", i.toString());
    this.bsModalRef = this.modalService.show(ImageAnuncioComponent, {class: "modal-content-anuncio", backdrop: true});
  }

  changeState(){
    this.state = !this.state;
  }

  changeVisibility(): string {
      return this.state ? '(Colapsar)' : '(Saiba mais...)';
  }

  changeDescription(anuncio: AnuncioFeed){
    return this.state && anuncio.descricao.length > 200 ?
      anuncio.descricao
      : anuncio.descricao.substring(0,200) + '...';
  }


}
