
export interface UserNivel {
  nome: string;
  imagem: string;
  nivelOrdem: number;
  numeroAceitos: number;
  texto:string
}

export function getNiveis() {
  return new Map<string, { nome: string, imagem: string, nivelOrdem: number, numeroAceitos: number, texto:string }>([
    ['T', { nome: 'TOBI', imagem: '/assets/_img/niveis/T1_(TOBI).svg', nivelOrdem: 1, numeroAceitos: 0, texto:'TOBI é o nível inicial da Tippz. Revise o seu perfil como prestador de serviços para desbravar sua nova carreira, mesmo que ainda não tenha experiência na plataforma.' }],
    ['I', { nome: 'ISI', imagem: '/assets/_img/niveis/T2_(ISI).svg', nivelOrdem: 2, numeroAceitos: 1, texto:'Este é o segundo nível. Isso significa que o prestador de serviços já completou pelo menos um projeto com qualificação positiva na Tippz e já está pronto para seguir trabalhando. Revise o seu perfil para conhecer melhor todas as suas habilidades.' }],
    ['P1', { nome: 'PLATINUM', imagem: '/assets/_img/niveis/T3_(PLATINUM).svg', nivelOrdem: 3, numeroAceitos: 200, texto:'Um prestador de serviços PRATA é um profissional com um histórico de pelo menos dois clientes satisfeitos. Isso significa que você já tem as suas primeiras estrelas e sua carreira como prestador de serviços da Tippz começou muito bem. Não esqueça de conferir seu perfil para sempre acompanhar o seu histórico de reputação.' }],
    ['P2', { nome: 'PENIEL', imagem: '/assets/_img/niveis/T4_(PENIEL).svg', nivelOrdem: 4, numeroAceitos: 1000,texto:'Um prestador de serviços PENIEL já trabalhou com pelo menos quatro clientes diferentes, conseguindo ótimos resultados. Para esses profissionais, a reputação é muito importante. Não perca a oportunidade de reconhecer seu talento no seu próximo projeto!' }],
    ['Z', { nome: 'ZION', imagem: '/assets/_img/niveis/T5_(ZION).svg', nivelOrdem: 5, numeroAceitos: 2000,texto:'Um prestador de serviços ZION com certeza é um profissional TOP! Seu talento é capaz de reconhecer os melhores clientes. Além de saber trabalhar de maneira remota, consegue entregar um excelente serviço. Sua carreira não para de crescer, e só falta você reconhecer isso. O que está esperando para contratar esse profissional?' }],
    ['H', { nome: 'HERO', imagem: '/assets/_img/niveis/Trófeu.svg', nivelOrdem: 6, numeroAceitos: 4000,texto:'O prestador de serviços TROFÉU é aquele que chegou ao nível mais alto da Tippz. Se você precisa de um profissional qualificado, você acabou de encontrá-lo. O prestador de serviços TROFÉU já demonstrou todo o seu talento através de serviços qualificados e compromissos assumidos com os clientes, além de ter excelente comunicação. Trabalhar com eles será um grande prazer.' }]
  ]);
}
