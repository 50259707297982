import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AnuncioService } from 'src/app/services/anuncio.service';

@Component({
  selector: 'app-modal-cancela-anuncio',
  templateUrl: './modal-cancela-anuncio.component.html',
  styleUrls: ['./modal-cancela-anuncio.component.scss']
})
export class ModalCancelaAnuncioComponent {
  motivo: string = "";
  public content : any;
  constructor(
    public bsModalRef:BsModalRef,
    private anuncioService: AnuncioService
  ){}

  fechaModal(){
    this.bsModalRef.hide();
  }

  cancelaAnuncio(){
    let id = this.content.idAnuncio != undefined ? this.content.idAnuncio : this.content.id;
    this.anuncioService.mudaStatus(id, 3, this.motivo)
    this.fechaModal()
    window.location.reload();
  }

}
