import { Component } from '@angular/core';
import {CadastroComponent} from "../cadastro/cadastro.component";
import {LoginComponent} from "../login/login.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UserService} from "../../../services/user.service";
import {NgToastService} from "ng-angular-popup";
import {LoginService} from "../../../services/login.service";

@Component({
  selector: 'app-senha',
  templateUrl: './senha.component.html',
  styleUrls: ['./senha.component.scss', '../../../../darkmode.scss']
})
export class SenhaComponent {

  public email:string="";
  public code:string="";
  public password1:string="";
  public password2:string="";
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public userService:LoginService,
    public toast:NgToastService
    ) {
    let emailAndCode = localStorage.getItem("emailAndCode");
    localStorage.removeItem("emailAndCode");
    if(emailAndCode != null) {
      let params = emailAndCode.split("&");
      this.email = params[0].replace("email=","");
      this.code = params[1].replace("code=","");
    }
  }
  public openModalCadastro() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(CadastroComponent, {class:'open-backdrop', backdrop:true});
  }

  public openModalLogin() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(LoginComponent,{backdrop:true});
  }

  public async sendEmailRecoverPassword() {
    let response = await this.userService.sendEmailRecoverPassword(this.email);
    if(response.status == 200) {
      this.toast.info({
        summary: response.message,
        duration: 5000,
        position: 'tr'
      });
      setTimeout(() => {
        this.bsModalRef.hide();
      }, 5000);
    } else {
      this.toast.warning({
        summary: response.message,
        duration: 5000,
        position: 'tr'
      });
    }
  }

  public async setNewPassword() {
    if(this.password1 == '' || this.password2 == '') {
      this.toast.warning({
        summary: "Preencha a senha e a confirmação da senha.",
        duration: 5000,
        position: 'tr'
      });
      return;
    }
    if(this.password1 != this.password2) {
      this.toast.warning({
        summary: "Senhas não são iguais",
        duration: 5000,
        position: 'tr'
      });
      return;
    }
    let response = await this.userService.setNewPassword(this.email, this.password1, this.code);
    if(response.status == 200) {
      this.toast.info({
        summary: response.message,
        duration: 5000,
        position: 'tr'
      });
      if(await this.userService.login(this.email, this.password1)) {
        // @ts-ignore
        this.bsModalRef.hide();
        document.location.href = '/';
      }
    } else {
      this.toast.warning({
        summary: response.message,
        duration: 5000,
        position: 'tr'
      });
      setTimeout(() => {
        this.bsModalRef.hide();
      }, 5000);
    }
  }

}
