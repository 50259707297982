import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  MeuAnuncio,
  MyAdType,
  MyAdsFilters,
} from 'src/app/models/anuncio/meuAnuncio';
import { MeuAnuncioService } from 'src/app/services/meu-anuncio.service';
import { UserLogged } from 'src/app/services/user-logged';

@Component({
  selector: 'app-meus-anuncios',
  templateUrl: './meus-anuncios.component.html',
  styleUrls: ['./meus-anuncios.component.scss','../anuncio.component.scss', '../../../../darkmode.scss']
})
export class MeusAnunciosComponent {
  private readonly initialFilters: MyAdsFilters = {
    pageMode: 'freelancer',
    status: undefined,
    title: '',
    providerName: '',
    currentPage: 1,
    totalPages: 1,
  };

  filters: MyAdsFilters = this.initialFilters;
  serviceProvidersLink: string =
    '/anuncio/meus-anuncios';
  myAds: MeuAnuncio[] = [];

  constructor(
    public routes: Router,
    private meuAnuncioService: MeuAnuncioService,
  ) {}

  ngOnInit(): void {
    this.getAdCardsByFilter();
  }

  getAdCardsByFilter(): void {
    this.meuAnuncioService.getByFilter(this.filters).subscribe((response) => {
      const {content, totalPages} = response;

      this.myAds = content;
      this.filters = { ...this.filters, totalPages };
    });
  }

  changePage(page: number) {
    if (this.filters.currentPage != page) {
      this.filters = { ...this.filters, currentPage: page };

      this.getAdCardsByFilter();
    }
  }

  changePageMode(pageMode: MyAdType) {
    this.filters = { ...this.initialFilters, pageMode };

    this.getAdCardsByFilter();
  }

  handleFilterChanges({ value: data }: FormGroup) {
    const title = data.title;
    const status = data.status;
    const providerName = data.providerName

    this.filters = { ...this.filters, status, title, providerName };

    this.getAdCardsByFilter();
  }
}
