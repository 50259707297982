import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.scss', '../../../../darkmode.scss']
})
export class MenuAdminComponent {

}
