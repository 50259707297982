import { Component } from '@angular/core';
import {LoginComponent} from "../login/login.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ApiService} from "../../../services/api.service";
import {User} from "../../../models/user/user";
import {LoginService} from "../../../services/login.service";
import {NgToastService} from "ng-angular-popup";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss', '../../../../darkmode.scss']
})
export class CadastroComponent {

  public appURL = environment.appURL;
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private apiService:ApiService,
    private loginService:LoginService,
    private toast:NgToastService
  ) {
  }

  public openModalLogin() {
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true});
  }

  public cadastrar(data: any){
    if(data.password == "" || data.passwordConfirm == "") {
      this.toast.warning({
        summary: "Informe sua senha e confirmação.",
        duration: 5000,
        position: 'tr'
      });
    } else if(data.password == data.passwordConfirm) {
      if(data.checkTermos == "") {
        this.toast.warning({
          summary: "Necessário aceitar termos.",
          duration: 5000,
          position: 'tr'
        });
      } else {
        this.apiService.post(ApiService.URL + 'user', data).subscribe(async resp => {
          let logged = await this.loginService.login(resp.email, data.password);
          if (logged) {
            // @ts-ignore
            cadastro_frelancer_gtag();
            this.bsModalRef.hide();
            document.location.href = '/perfil/edit';
          } else {
            this.toast.warning({
              summary: 'Usuário não encontrado ou senha incorreta',
              duration: 5000,
              position: 'tr'
            });
          }
        }, error => {
          this.toast.warning({
            summary: error.error.message,
            duration: 5000,
            position: 'tr'
          });
        });
      }
    } else {
      this.toast.warning({
        summary: "Senhas não são iguais.",
        duration: 5000,
        position: 'tr'
      });
    }
  }

  protected readonly ApiService = ApiService;
  protected readonly environment = environment;
}
