import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Leilao} from "../../../../models/admin/leilao";
import {LeilaoService} from "../../../../services/leilao.service";
import {LeilaoModel} from "../../../../models/admin/leilaoModel";
import {CategoriaService} from "../../../../services/categoria.service";
import {Categoria} from "../../../../models/categoria/categoria";

@Component({
  selector: 'app-modal-leilao',
  templateUrl: './modal-leilao.component.html',
  styleUrls: ['./modal-leilao.component.scss']
})
export class ModalLeilaoComponent implements OnInit {

  public bsModalRef: BsModalRef | undefined;
  public leilao:Leilao = new LeilaoModel();
  public categorias:Categoria[] = new Array<Categoria>();

  constructor(
    private serviceModal: BsModalService,
    private leilaoService:LeilaoService,
    private categoriaService:CategoriaService
  ) {
    this.getCategorias();
  }

  ngOnInit() {

  }

  async getCategorias() {
    this.categorias = await this.categoriaService.getByNivel(1).toPromise();
  }

  salvarLeilao(){
    this.leilaoService.save(this.leilao);
  }
}
