import { Component } from '@angular/core';

@Component({
  selector: 'app-termos',
  templateUrl: './termos.component.html',
  styleUrls: ['./termos.component.scss', '../../../darkmode.scss']
})
export class TermosComponent {

}
