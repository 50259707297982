import {Proposta} from "../anuncio/proposta";
import {AnuncioFeed} from "../anuncio/anuncioFeed";
import {Conversation} from "./conversation";
import {ConversationAnexo} from "./conversationAnexo";

export class ConversationModel implements Conversation {
  id!: number;
  user1Id!:number;
  user1Name!:string;
  user1Picture!:string;
  user1LastActive!:Date;
  user2Id!:number;
  user2Name!:string;
  user2Picture!:string;
  user2LastActive!:Date;
  createdAt!:Date;
  updatedtedAt!:Date;
  anuncio!:AnuncioFeed;
  proposta!:Proposta;
  unread!:number;
  anexos!:ConversationAnexo[];
  idRequest!:number;
}
