import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlaySoundService {
  constructor() {

  }

  play() {
    let audio: HTMLAudioElement = new Audio('/assets/notification_sound.mp3');
    audio.play();
  }
}
