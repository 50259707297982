import { Component } from '@angular/core';
import {User} from "../../../models/user/user";
import {UserLogged} from "../../../services/user-logged";
import {LoginService} from "../../../services/login.service";
import {Router} from "@angular/router";
import {UtilsModule} from "../../../utils";

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent {

  public user:User;
  public isLogged: boolean = false;
  constructor(
    private userLogged: UserLogged,
    private loginService: LoginService,
    public router: Router,
  ) {
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;
  }

  logout(){
    this.loginService.logout();
  }

  openPerfil() {
    let name = UtilsModule.accentsTidy(this.user.name.replaceAll(" ", "-")).toLowerCase();
    localStorage.removeItem("userProfile");
    window.location.href = this.router.createUrlTree(['freelancer/' + name]).toString();
    this.router.navigate([]);
  }

  changeDarkTheme(){
    this.user.darkMode = !this.user.darkMode;
    this.loginService.updateUserViewMode(this.user);
    localStorage.setItem("user", JSON.stringify(this.user));
    this.userLogged.user = this.user;
    if(!this.user.darkMode){
      // @ts-ignore
      document.getElementById("logo").setAttribute("src", "/assets/_img/Logotipo_Medium.png");
      document.documentElement.setAttribute('data-theme', 'light');
    } else{
      // @ts-ignore
      document.getElementById("logo").setAttribute("src", "/assets/_img/Logotipo_white_Medium.png");
      document.documentElement.setAttribute('data-theme', 'dark');
    }
  }

  changePerfil() {
    this.user = JSON.parse(<string>localStorage.getItem("user"));
    this.user.prestador = !this.user.prestador;
    this.userLogged.user = this.user;
    localStorage.setItem("user", JSON.stringify(this.user));
    this.loginService.updateUser(this.user);
  }

  changeOnline() {
    this.user = JSON.parse(<string>localStorage.getItem("user"));
    this.user.online = !this.user.online;
    this.userLogged.user = this.user;
    localStorage.setItem("user", JSON.stringify(this.user));
    this.loginService.updateUserOnline(this.user);
  }
}

