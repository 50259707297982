import {Dashboard} from "./dashboard";

export class DashboardModel implements Dashboard {
  totalActiveUsers: number = 0;
  totalInactiveUsers: number = 0;
  totalPublishedAnnounciments: number = 0;
  totalConcludedAnnounciments: number = 0;
  totalProposals: number = 0;
  totalApprovedProposals: number = 0;
  totalAuction: number = 0;
  totalAuctionBid: number = 0;
  totalBuyedCoins: number = 0;
  totalUsedCoins: number = 0;
  totalValueMovimented: number = 0;
  totalValueGained: number = 0;
}
