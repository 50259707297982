import { Component } from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {LoginService} from "../../../services/login.service";
import {Router} from "@angular/router";
import {NgToastService} from "ng-angular-popup";
import {LoaderService} from "../../../services/loader.service";
import {User} from "../../../models/user/user";
import {UtilsModule} from "../../../utils";
import {environment} from "../../../environments/environment";
import {AnuncioService} from "../../../services/anuncio.service";

@Component({
  selector: 'app-aceite-proposta',
  templateUrl: './aceite-proposta.component.html',
  styleUrls: ['./aceite-proposta.component.scss', '../../../../darkmode.scss']
})
export class AceitePropostaComponent {
  public appURL = environment.appURL;
  public chatingWith: User|undefined;
  public sender:number = 0;
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private loginService:LoginService,
    private router:Router,
    private toast:NgToastService,
    private loaderService:LoaderService,
    private anuncioService:AnuncioService
  ) {

  }
  async getProfile() {
    this.chatingWith = await this.loginService.getUser(this.sender);
  }

  async showProfile() {
    localStorage.setItem("userProfile", JSON.stringify(this.chatingWith));
    // @ts-ignore
    let name = UtilsModule.accentsTidy(this.chatingWith.name.replaceAll(" ", "-")).toLowerCase();
    window.location.href = this.router.createUrlTree(['client/' + name]).toString();
  }

  openGarantia() {
    this.bsModalRef.hide();
    let proposta = JSON.parse(<string> localStorage.getItem("proposta"));
    this.anuncioService.setPropostaAceita(proposta.id);
    window.location.href = 'checkout/garantia';
  }
}
