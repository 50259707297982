import { Component } from '@angular/core';
import {UtilsModule} from "../../../utils";
import {AceitePropostaComponent} from "../../modais/aceite-proposta/aceite-proposta.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ChatService} from "../../../services/chat.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoginService} from "../../../services/login.service";
import {UserLogged} from "../../../services/user-logged";
import {WebSocketAPI} from "../../../services/WebSocketAPI";
import {EscolhaFreelancerComponent} from "../../modais/escolha-freelancer/escolha-freelancer.component";

@Component({
  selector: 'app-proposta-selecionada',
  templateUrl: './proposta-selecionada.component.html',
  styleUrls: ['./proposta-selecionada.component.scss']
})
export class PropostaSelecionadaComponent {
  public tolltipChance:boolean = false;
  protected readonly UtilsModule = UtilsModule;

  constructor(
    private router: Router,
    private activatedRouter:ActivatedRoute,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
  ) {}

  openModalConfirmacao() {
      this.bsModalRef = this.modalService.show(EscolhaFreelancerComponent);
  }
}
