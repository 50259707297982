import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";
import {UserLogged} from "../../services/user-logged";
import {LoginComponent} from "../modais/login/login.component";
import {DownloadappComponent} from "../modais/downloadapp/downloadapp.component";
import {User} from "../../models/user/user";
import {NgForm} from "@angular/forms";
import { NgOptimizedImage } from '@angular/common'
import {UtilsModule} from "../../utils";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../../../darkmode.scss']
})

export class HeaderComponent {

  public isLogged:boolean = false;
  public user:User;
  public logo: string | undefined;
  public bsModalRef: BsModalRef | undefined;
  public search:any = localStorage.getItem('searchFilter');



  constructor(
    public routes: Router,
    private modalService: BsModalService,
    private userLogged:UserLogged
  ) {
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;

    if(this.user.darkMode) {
      this.logo = '/assets/_img/Logotipo_white_Medium.png';
    }else{
      this.logo = '/assets/_img/Logotipo_Medium.png';
    }
  }

  public openModalLogin() {
    this.bsModalRef = this.modalService.show(LoginComponent, {backdrop: true});
  }

  public openModalDownloadApp() {
    this.bsModalRef = this.modalService.show(DownloadappComponent, {backdrop: true});
  }

  protected readonly AuthService = UserLogged;

  public searchAnuncios(search:string) {
    localStorage.setItem('searchFilter', search);
    localStorage.setItem('typeFilter', 'search');
    this.userLogged.isLogged ? window.location.href = '/' : window.location.href = '/feed';
  }

  public resetFilterAndGoHome() {
    this.userLogged.resetFilterAndGoHome();
    this.routes.navigate(['']);
  }


  protected readonly UtilsModule = UtilsModule;
}
