import { Component } from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss', '../../../../darkmode.scss']
})
export class SitemapComponent {

  public sitemap:string="";

  constructor(
    private http:HttpClient
  ) {
    this.loadsitemap();
  }

  async loadsitemap() {
    window.location.href = ApiService.URL + "config/sitemap";
  }
}
