import { Component } from '@angular/core';

@Component({
  selector: 'app-ver-proposta',
  templateUrl: './ver-proposta.component.html',
  styleUrls: ['./ver-proposta.component.scss']
})
export class VerPropostaComponent {

}
