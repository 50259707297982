import { Component } from '@angular/core';
import {UserLogged} from "../../../services/user-logged";
import {AnuncioService} from "../../../services/anuncio.service";
import {User} from "../../../models/user/user";
import {AnuncioFeed} from "../../../models/anuncio/anuncioFeed";
import {NgToastService} from "ng-angular-popup";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-model-pergunta',
  templateUrl: './model-pergunta.component.html',
  styleUrls: ['./model-pergunta.component.scss', '../../../../darkmode.scss']
})
export class ModelPerguntaComponent {

  public question:string="";
  private user:User;
  private anuncio:AnuncioFeed;
  constructor(
    private userLogged:UserLogged,
    private anuncioService:AnuncioService,
    public bsModalRef:BsModalRef,
    private toast:NgToastService
  ) {
    this.user = userLogged.user;
    this.anuncio = JSON.parse(<string> localStorage.getItem("viewAnuncio"));
  }
  enviarPergunta() {
    if(this.question != null && this.question.length >= 20) {
      this.anuncioService.adicionarInteressado(this.user, this.anuncio, this.question);
      this.bsModalRef.hide();
    } else {
      this.toast.warning({
        summary: 'Preencha a pergunta com pelo menos 20 caracteres.',
        duration: 5000,
        position: 'tr'
      });
    }
  }
}
