import {Component, OnInit} from '@angular/core';
import {UserLogged} from "../../../services/user-logged";
import {LoginComponent} from "../../modais/login/login.component";
import {CadastroComponent} from "../../modais/cadastro/cadastro.component";
import {DownloadappComponent} from "../../modais/downloadapp/downloadapp.component";
import {User} from "../../../models/user/user";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";
import {AppService} from "../../../app.service";

@Component({
  selector: 'app-controle-home-menu',
  templateUrl: './controle-menu-home.component.html',
  styleUrls: ['./controle-menu-home.component.scss', '../../../../darkmode.scss']
})
export class ControleMenuHomeComponent implements OnInit {

  public user:User;
  public isLogged:boolean = false;
  public bsModalRef: BsModalRef | undefined;
  public shouldInstall:boolean = true;

  constructor(
    private modalService: BsModalService,
    public routes:Router,
    private userLogged:UserLogged,
    public pwa:AppService
  ) {
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;
  }

  public ngOnInit() {
    this.shouldInstall = this.pwa.shouldInstall();
  }

  public openModalLogin() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(LoginComponent);
  }

  public openModalDownloadApp() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(DownloadappComponent);
  }

  public openModalCadastro() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(CadastroComponent);
  }


  protected readonly AuthService = UserLogged;
  protected readonly UserLogged = UserLogged;
}
