import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {UserLogged} from "../../../services/user-logged";
import {User} from "../../../models/user/user";

@Component({
  selector: 'app-imagem-user',
  templateUrl: './imagem-user.component.html',
  styleUrls: ['./imagem-user.component.scss', '../../../../darkmode.scss']
})
export class ImagemUserComponent {
  public user:User;
  public isLogged:boolean = false;
  constructor(
    public routes:Router,
    public userLogged:UserLogged
  ) {
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;
  }
}
