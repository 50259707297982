import {Component, Input} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {CategoriaService} from "../../../services/categoria.service";
import {Categoria} from "../../../models/categoria/categoria";
import {Router} from "@angular/router";
import {NgToastService} from "ng-angular-popup";
import Editor from '@ckeditor/ckeditor5-build-classic';
import {UserLogged} from "../../../services/user-logged";
import {AnuncioService} from "../../../services/anuncio.service";
import {CategoriaModel} from "../../../models/categoria/categoriaModel";
import {User} from "../../../models/user/user";


@Component({
  selector: 'app-forms-novo-anuncio',
  templateUrl: './forms-novo-anuncio.component.html',
  styleUrls: ['./forms-novo-anuncio.component.scss', '../../../../darkmode.scss']
})
export class FormsNovoAnuncioComponent {

  protected readonly onsubmit = onsubmit;

  // @ts-ignore
  @Input() user:User;

  public searchCategoria:string = "";
  public searchSubcategoria:any;
  public cat0Filtradas: Categoria[] = new Array<Categoria>()
  public cat1Filtradas: Categoria[] = new Array<Categoria>()
  public categorias: Categoria[] = new Array<Categoria>();
  public categoriasNivel0: Categoria[] = new Array<Categoria>();
  public categoriasNivel1: Categoria[] = new Array<Categoria>();
  public categoriasNivel2: Categoria[] = new Array<Categoria>();
  base64textString = [];
  public stepValid: boolean = false;
  public Editor = Editor;
  // @ts-ignore
  public subcategoriaSelecionada: Categoria = new CategoriaModel();
  public categoriaSelecionada: Categoria = new CategoriaModel();
  public tipoAnuncio: string = '';
  public habilidades: Categoria[] = [];
  public prazoEntrega: Date | undefined;
  public valorOrcamento: number = 0;
  @Input() anuncio: any;
  data: any;
  id: any;
  flagData: boolean = true;

  public newAnuncio = this.fb.group({
    searchCategoria: [''],
    searchSubcategoria: [''],
    categoria: [''],
    subcategoria: [''],
    titulo: [''],
    necessidade: [''],
    tipoAnuncio: [''],
    disponibilidade: [''],
    descricao: [''],
    prazoEntrega: [''],
    habilidades: [''],
    valor: [],
  });

  constructor(
    private routes: Router,
    private fb: FormBuilder,
    private toast: NgToastService,
    private categoriaService: CategoriaService,
    private userLogged: UserLogged,
    private anuncioService: AnuncioService
  ) {
    this.subcategoriaSelecionada.id = 0;
    this.categoriaSelecionada.id = 0;
    const {idAnuncio} = history.state;
    if (idAnuncio) {
      this.id = idAnuncio
      this.fillFormWithAnuncio(idAnuncio)
    }
  }


  ngOnInit() {
    this.loadCategorias();
  }

  private async fillFormWithAnuncio(idAnuncio: number) {
    let anuncio = await this.anuncioService.getById(idAnuncio).toPromise()
    const date = new Date(anuncio.dttPublicacao).toISOString().split('T')[0];
    this.categoriaSelecionada = this.categoriasNivel0.filter(c => c.id == anuncio.categoriaPrincipal.categoriaPaiId)[0];
    this.filterCategoria(this.categoriaSelecionada);
    this.subcategoriaSelecionada = anuncio.categoriaPrincipal;
    this.tipoAnuncio = anuncio.tipoAnuncio;
    this.newAnuncio.patchValue({
      titulo: anuncio.titulo,
      necessidade: anuncio.necessidade,
      descricao: anuncio.descricao,
      disponibilidade: anuncio.disponibilidade,
      tipoAnuncio: anuncio.tipoAnuncio,
      valor: anuncio.valor,
      prazoEntrega: date,
      categoria: anuncio.categoriaPrincipal.id.toString()
    });
  }

  async loadCategorias() {
    this.categoriasNivel0 = await this.categoriaService.getByNivel(0).toPromise();
    this.categorias = this.categoriasNivel0;
  }

  step: any = 1;

  nextStep() {
    console.log(this.anuncio.prazoEntrega);
    this.validStep();
    if (this.stepValid) {
      this.step = this.step + 1;
      this.stepValid = false;
      this.validStep()
    } else {
      this.toast.warning({
        summary: 'Preencha as informações da etapa para avançar.',
        duration: 5000,
        position: 'tr'
      });
    }
  }

  prevStep() {
    this.validStep()
    if (this.step == 1)
      this.routes.navigate(["/"]);
    else
      this.step = this.step - 1;
    this.searchCategoria = "";
    this.searchSubcategoria = "";
  }

  validStep() {
    switch (this.step) {
      case 1:
        this.stepValid = (
             this.newAnuncio.value.titulo != null
          && this.newAnuncio.value.titulo != ""
          && this.newAnuncio.value.descricao != null
          && this.newAnuncio.value.descricao != ""
        );
        break;
      case 2:
        this.stepValid = (
             this.newAnuncio.value.categoria != null
          && this.newAnuncio.value.categoria != ""
          && this.newAnuncio.value.subcategoria != null
          && this.newAnuncio.value.subcategoria != ""
        );
        break;
      case 3:
        this.stepValid = (
           this.newAnuncio.value.prazoEntrega !== null
        && this.newAnuncio.value.valor !== null
        && this.newAnuncio.value.valor !== 0
        && typeof this.newAnuncio.value.valor === 'number'
        && !isNaN(this.newAnuncio.value.valor)
        && this.newAnuncio.value.tipoAnuncio != null
        && this.newAnuncio.value.tipoAnuncio != undefined
        && this.newAnuncio.value.tipoAnuncio != "");
        break;
      /*case 4:
        this.stepValid = (

        );
        break;*/
    }
  }

  getStep() {
    return this.step;
  }

  modalFinishedPublic() {

    if (!this.id) {
      let dataNewAnuncio = {
        titulo: this.newAnuncio.value.titulo,
        tipoAnuncio: this.newAnuncio.value.tipoAnuncio,
        valor: this.newAnuncio.value.valor,
        descricao: this.newAnuncio.value.descricao,
        deadline: this.newAnuncio.value.prazoEntrega,
        categoriaPrincipal: this.subcategoriaSelecionada,
        necessidade: this.newAnuncio.value.necessidade,
        disponibilidade: this.newAnuncio.value.disponibilidade,
        imagens: this.base64textString,
        cliente: this.userLogged.user,
        tags: this.habilidades
      }
      this.anuncioService.salvar(dataNewAnuncio);
    } else {
      let dataEditAnuncio = {
        id: this.id,
        titulo: this.newAnuncio.value.titulo,
        tipoAnuncio: this.newAnuncio.value.tipoAnuncio,
        valor: this.newAnuncio.value.valor,
        descricao: this.newAnuncio.value.descricao,
        deadline: this.newAnuncio.value.prazoEntrega,
        categoriaPrincipal: this.subcategoriaSelecionada,
        necessidade: this.newAnuncio.value.necessidade,
        disponibilidade: this.newAnuncio.value.disponibilidade,
        imagens: this.base64textString,
        cliente: this.userLogged.user,
        tags: this.habilidades
      }
      this.anuncioService.salvar(dataEditAnuncio);
    }
  }

  async filterCategoria(categoria: Categoria) {
    this.categoriaSelecionada = categoria;
    categoria.filhas = await this.categoriaService.getByCategoriaPai(categoria.id).toPromise();
    this.categoriasNivel1 = categoria.filhas;
    this.categorias = this.categoriasNivel0.concat(this.categoriasNivel1);
    this.validStep();
  }

  async selectSubCategoria(categoria: Categoria) {
    this.subcategoriaSelecionada = categoria;
    this.subcategoriaSelecionada.filhas = await this.categoriaService.getByCategoriaPai(categoria.id).toPromise();
    this.categoriasNivel2 = this.subcategoriaSelecionada.filhas;
    this.categorias = this.categorias.concat(this.categoriasNivel2);
    this.validStep();
  }

  onUploadChange(evt: any) {
    if (this.base64textString.length <= 4) {
      const file = evt.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    }
  }

  handleReaderLoaded(e: any) {
    // @ts-ignore
    this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));
  }

  selectHabilidades(cat2: Categoria) {
    if (this.habilidades.indexOf(cat2) == -1) {
      if (this.habilidades.length < 3) {
        this.habilidades.push(cat2);
      }
    } else {
      this.habilidades = this.habilidades.filter(h => h != cat2);
    }
  }

  updateTipoAnuncio(args: string) {
    this.tipoAnuncio = args;
    this.newAnuncio.patchValue({'tipoAnuncio': args});
    this.validStep();
  }

  alterarPrazo() {
    this.flagData = !this.flagData;
    if (this.flagData) {
      this.newAnuncio.patchValue({
        'prazoEntrega': null
      });
      return this.newAnuncio.controls['prazoEntrega'].disable()
    } else {
      return this.newAnuncio.controls['prazoEntrega'].enable();
    }
  }

  public filterCategorias0(){
    if(this.cat0Filtradas.length < 1 && this.searchCategoria !== ""){
      this.cat0Filtradas = this.categoriasNivel0;
    } else{
      if(this.searchCategoria === ""){
        this.cat0Filtradas = [];
      } else{
        this.cat0Filtradas = this.categoriasNivel0.filter(c => c.nome.toLowerCase().includes(this.searchCategoria.toLowerCase())).slice(0, 8);
      }
    }

  }
  public filterSubcategorias(){
    if(this.cat1Filtradas.length < 1 && this.searchSubcategoria !== ""){
      this.cat1Filtradas = this.categoriasNivel1;
    } else{
      if(this.searchSubcategoria == "" || this.searchSubcategoria == undefined){
        this.cat1Filtradas = [];
      } else{
        this.cat1Filtradas = this.categoriasNivel1.filter(c => c.nome.toLowerCase().includes(this.searchSubcategoria.toLowerCase())).slice(0, 8);
      }
    }

  }

  public filterCategoriasDefault(){
    return this.categoriasNivel0.slice(0,8);
  }

  protected readonly alert = alert;
  protected readonly document = document;
}
