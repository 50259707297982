import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {finalize, Observable} from 'rxjs';
import {LoaderService} from "../../../services/loader.service";
import {environment} from "../../../environments/environment";
import {UserLogged} from "../../../services/user-logged";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    public loaderService:LoaderService,
    public userLogged: UserLogged
  ) {}

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.show();
    this.userLogged.user.lastActive = new Date();
    return next.handle(request).pipe(finalize(async () => {
      await this.delay(environment.waitLoader);
      this.loaderService.hide();
      })
    );
  }
}
