import {Plano} from "../plano";
import {Payment} from "./payment";

export class PaymentModel implements Payment {
  id!: number;
  amountTotal!: number;
  status!: string;
  coins!: number;
  typePayment!: number;
  dttPayment!: Date;
  plano!: Plano;
}

