import { Component } from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {Router} from "@angular/router";
import { User } from "../../../models/user/user";
import {UserLogged} from "../../../services/user-logged";
import { NgForm } from "@angular/forms";
import {Proposta} from "../../../models/anuncio/proposta";
import {PropostaModel} from "../../../models/anuncio/propostaModel";
import {AnuncioFeed} from "../../../models/anuncio/anuncioFeed";
import {UtilsModule} from "../../../utils";
import {UserHabilidade} from "../../../models/user/userHabilidade";
import {NgToastService} from "ng-angular-popup";
import {ConversationAnexo} from "../../../models/chat/conversationAnexo";
import {ConversationModel} from "../../../models/chat/conversationModel";
import {ConversationAnexoModel} from "../../../models/chat/conversationAnexoModel";

@Component({
  selector: 'app-nova-proposta',
  templateUrl: './nova-proposta.component.html',
  styleUrls: ['./nova-proposta.component.scss']
})
export class NovaPropostaComponent {
  public anuncio:AnuncioFeed;
  public isLogged:boolean = false;
  public user:User;
  public proposta:Proposta = new PropostaModel();
  public habilidades:UserHabilidade[] = new Array<UserHabilidade>();
  public base64textString:string = "";

  constructor(
    private routes:Router,
    private apiService:ApiService,
    private userLogged:UserLogged,
    private toast:NgToastService
  ) {
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;
    this.anuncio = JSON.parse(<string> localStorage.getItem('viewAnuncio'));
    if(routes.url.includes('edit')) {
      this.proposta = JSON.parse(<string> localStorage.getItem('proposta'));
    }
  }

  public salvarProposta() {
    if(this.validarProposta()) {
      this.proposta.anuncioId = this.anuncio.id;
      this.proposta.prestadorId = this.user.id;
      this.proposta.habilidades = "";
      for (let h of this.habilidades)
        this.proposta.habilidades += h.categoria.nome + ", ";
      this.apiService.post(ApiService.URL + 'proposta', this.proposta).subscribe(resp => {
        // @ts-ignore
        envio_propostas_gtag();
        let titulo = UtilsModule.accentsTidy(this.anuncio.titulo.replaceAll(" ", "-")).toLowerCase();
        localStorage.setItem("viewAnuncio", JSON.stringify(this.anuncio));
        this.userLogged.user.currency.value -= this.anuncio.categoriaPrincipal.lance;
        this.user = this.userLogged.user;
        localStorage.setItem("user", JSON.stringify(this.user));
        this.routes.navigate(['job/' + this.anuncio.id + '-' + titulo]);
      });
    }
  }

  addRemHabilidade(h: UserHabilidade) {
    if(this.habilidades.find(hab => h.categoria.id == hab.categoria.id))
      this.habilidades = this.habilidades.filter(hab => h.categoria.id != hab.categoria.id);
    else {
      if(this.habilidades.length < 5)
        this.habilidades.push(h);
    }
  }

  isChecked(h: UserHabilidade) {
    return this.habilidades.find(hab => h.categoria.id == hab.categoria.id);
  }

  protected readonly UtilsModule = UtilsModule;

  private validarProposta() {
    let p = this.proposta;
    let sucesso = true;
    if(this.habilidades == null || this.habilidades.length <= 0) {
      this.toast.warning({
        summary: "Selecione suas habilidades para atender essa demanda.",
        duration: 5000,
        position: 'tr'
      });
      sucesso = false;
    }
    if(p.valor == null || p.valor <= 0) {
      this.toast.warning({
        summary: "Informe o valor do seu orçamento.",
        duration: 5000,
        position: 'tr'
      });
      sucesso = false;
    }
    if(p.previsao == null || p.previsao == "") {
      this.toast.warning({
        summary: "Informe uma previsão para concluir o trabalho.",
        duration: 5000,
        position: 'tr'
      });
      sucesso = false;
    }
    if(p.descricao == null || p.descricao == "" || p.descricao.length < 20) {
      this.toast.warning({
        summary: "Informe uma descrição mais detalhada das suas experiências, habilidades e o porque você é o profissional ideal (min.: 20 caracteres).",
        duration: 5000,
        position: 'tr'
      });
      sucesso = false;
    }
    if(p.pitch == null || p.pitch == "") {
      this.toast.warning({
        summary: "Informe uma descrição curta do porque você deve ser selecionado.",
        duration: 5000,
        position: 'tr'
      });
      sucesso = false;
    }
    if(p.disponibilidade == null || p.disponibilidade == "") {
      this.toast.warning({
        summary: "Informe sua disponibilidade.",
        duration: 5000,
        position: 'tr'
      });
      sucesso = false;
    }
    if(p.infos == null || p.infos == "") {
      this.toast.warning({
        summary: "Informe duas dúvidas ou informações necessárias para realizar o trabalho.",
        duration: 5000,
        position: 'tr'
      });
      sucesso = false;
    }
    if(p.experiencia == null || p.experiencia == "") {
      this.toast.warning({
        summary: "Informe sua experiência com esse tipo de trabalho.",
        duration: 5000,
        position: 'tr'
      });
      sucesso = false;
    }
    return sucesso;
  }

  validarOrcamento(){

  }

  handleReaderLoaded(e:any) {
    // @ts-ignore
    let file = 'data:image/png;base64,' + btoa(e.target.result);
    let anexo:ConversationAnexo = new ConversationAnexoModel();
    anexo.file = file;
    if(this.proposta.anexos == null)
      this.proposta.anexos = [];
    this.proposta.anexos.push(anexo);
  }

  onUploadChange(evt: any) {
    if(this.proposta.anexos == null || this.proposta.anexos.length <= 4) {
      const file = evt.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    }
  }
}
