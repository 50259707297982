import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {CategoriasComponent} from "./categorias/categorias.component";
import {MoedasComponent} from "./moedas/moedas.component";
import {LeiloesComponent} from "./leiloes/leiloes.component";
import {AdminRoutingModule} from "./admin-routing.module";
import { RouterModule } from '@angular/router';
import {AdminComponent} from "./admin.component";
import { MenuAdminComponent } from './menu-admin/menu-admin.component';
import { CategoriaFormComponent } from './categorias/categoria-form/categoria-form.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { UsuariosTippzComponent } from './usuarios-tippz/usuarios-tippz.component';
import { NgbModalModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalLeilaoComponent } from './leiloes/modal-leilao/modal-leilao.component';
import { PaymentsComponent } from './payments/payments.component';

@NgModule({
    declarations: [
        AdminComponent,
        DashboardComponent,
        CategoriasComponent,
        MoedasComponent,
        LeiloesComponent,
        MenuAdminComponent,
        CategoriaFormComponent,
        ModalLeilaoComponent,
        PaymentsComponent
    ],
    exports: [
        MenuAdminComponent
    ],
    imports: [
        CommonModule, AdminRoutingModule, FormsModule, ReactiveFormsModule, NgbPaginationModule, NgbTypeaheadModule, NgbModalModule
    ]
})
export class AdminModule { }
