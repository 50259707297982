import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from "../../auth.guard";
import {BrowserModule} from "@angular/platform-browser";
import {InboxComponent} from "./inbox.component";
import {ChatComponent} from "./chat/chat.component";
import {DepositoGarantiaComponent} from "../deposito-garantia/deposito-garantia.component";

const routes: Routes = [
  {
    path: 'inbox', component: InboxComponent, canActivate: [AuthGuard], children: [
      {path: 'chat', component: ChatComponent, canActivate: [AuthGuard]},
      {path: 'chat/:usuario', component: ChatComponent, canActivate: [AuthGuard]},
      {path: 'anuncio/:id', component: ChatComponent, canActivate: [AuthGuard]}
    ]
  },
  {path:'garantia', component: DepositoGarantiaComponent},

];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class InboxRoutingModule {

}
