import { Component } from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
@Component({
  selector: 'app-escolha-freelancer',
  templateUrl: './escolha-freelancer.component.html',
  styleUrls: ['./escolha-freelancer.component.scss', '../../../../darkmode.scss']
})
export class EscolhaFreelancerComponent {
  constructor(
    public bsModalRef:BsModalRef,
  ) {}
}
