import { Component } from '@angular/core';
import {CategoriaService} from "../../../services/categoria.service";
import {Categoria} from "../../../models/categoria/categoria";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CategoriaFormComponent} from "./categoria-form/categoria-form.component";
import {UserLogged} from "../../../services/user-logged";
import {User} from "../../../models/user/user";
import {Router} from "@angular/router";
import {take} from "rxjs/operators";
import {CategoriaModel} from "../../../models/categoria/categoriaModel";

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss', '../../../../darkmode.scss']
})
export class CategoriasComponent {

  public categorias:Categoria[] = new Array<Categoria>();
  public bsModalRef: BsModalRef | undefined;
  public nivel:number = 0;
  public user:User;

  constructor(
    private categoriaService:CategoriaService,
    private modalService:BsModalService,
    private userLogged:UserLogged,
    private router:Router
  ) {
    this.user = userLogged.user;
    if(this.user.access == 0) {
      router.navigate(['/']);
    } else {
      this.loadCategorias(0);
    }
  }

  public async loadCategorias(id:number) {
    if(id == 0) {
      this.categorias = await this.categoriaService.getByNivel(id).toPromise();
    } else {
      this.categorias = await this.categoriaService.getByCategoriaPai(id).toPromise();
    }
    this.categorias.map(async (c) => {
      c.filhas = await this.categoriaService.getByCategoriaPai(c.id).toPromise();
    });
  }

  openCategoria(c?: Categoria) {
    if(!c)
      c = new CategoriaModel();
    localStorage.setItem("editCategory", JSON.stringify(c));
    this.bsModalRef = this.modalService.show(CategoriaFormComponent);
    this.modalService.onHide.pipe().subscribe(() => {
      if(c?.nivel == null || c?.nivel == 0)
        this.loadCategorias(0);
      else
        { // @ts-ignore
          this.loadCategorias(c.categoriaPaiId);
        }
    });
  }

  verCategoriasFilhas(c: Categoria) {
    this.nivel = c.nivel + 1;
    this.loadCategorias(c.id);
  }

  excluirCategoria(c: Categoria) {
    this.categoriaService.delete(c.id);
    this.categorias = this.categorias.filter(c1 => c1 != c);
  }
}
